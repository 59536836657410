import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { AlertVariant, AlertOnClose } from './Alert.types';

const StyledPolygon = styled.polygon<{ variant?: AlertVariant }>`
  fill: ${(props) => {
    switch (props.variant) {
      case 'error':
        return props.theme.colors.error.dark;
      case 'warning':
        return props.theme.colors.warning.dark;
      case 'success':
        return props.theme.colors.success.dark;
      default:
        return props.theme.colors.info.dark;
    }
  }};
`;

const CloseSvg: FunctionComponent<{
  variant?: AlertVariant;
  onClose: AlertOnClose;
}> = ({ variant, onClose }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    role="button"
    onClick={onClose}
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <polygon points="0 0 16 0 16 16 0 16" />
        <StyledPolygon
          variant={variant}
          points="12.3802345 3.94 11.4355109 3 7.69011725 6.72666667 3.94472362 3 3 3.94 6.74539363 7.66666667 3 11.3933333 3.94472362 12.3333333 7.69011725 8.60666667 11.4355109 12.3333333 12.3802345 11.3933333 8.63484087 7.66666667"
        />
      </g>
    </g>
  </svg>
);

export default CloseSvg;
