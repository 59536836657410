import { useEffect } from 'react';
import useFetch from '../hooks/useFetch';
import { SearchDataType } from '../types/SearchData.types';

const { REACT_APP_BASE_URL } = process.env;

function getURL(
  pageNumber: number,
  limit: number,
  searchText = '',
  userOrMessages = 'users',
) {
  const url = `${REACT_APP_BASE_URL}/search/?page=${pageNumber}&limit=${limit}&usersOrMessages=${userOrMessages}&searchTerm=${searchText}`;
  return url;
}

export function useSearchData(
  pageNumber: number,
  limit: number,
  searchText = '',
  currentTab: string,
): SearchDataType {
  const {
    dataAPI: searchData,
    isLoading: isLoadingSearch,
    error: searchError,
    fetchApi: fetchSearch,
  } = useFetch();

  const { searchData: searchItems = [], total = 0 } = searchData;
  useEffect(() => {
    const getSearchData = async () => {
      const searchItemsURL = getURL(pageNumber, limit, searchText, currentTab);
      await fetchSearch(searchItemsURL);
    };

    if (!searchError) getSearchData();
  }, [pageNumber, limit, searchText, currentTab, searchError]);

  return {
    loading: isLoadingSearch,
    total,
    searchItems,
    error: searchError,
  };
}
