import styled from 'styled-components';
import { variant } from 'styled-system';
import { animated } from 'react-spring';

interface AnimatedAlertContainerProps {
  variant?: 'success' | 'warning' | 'error' | 'info';
}

export const AnimatedAlertContainer = styled(
  animated.div,
)<AnimatedAlertContainerProps>`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 4px;
  font-size: 0.875rem;
  line-height: 1.25rem;

  ${(props) =>
    variant({
      variants: {
        success: {
          background: props.theme.colors.success.light,
          color: props.theme.colors.success.dark,
        },
        warning: {
          background: props.theme.colors.warning.light,
          color: props.theme.colors.warning.dark,
        },
        error: {
          background: props.theme.colors.error.light,
          color: props.theme.colors.error.dark,
        },
        info: {
          background: props.theme.colors.info.light,
          color: props.theme.colors.info.dark,
        },
      },
    })}
`;

AnimatedAlertContainer.defaultProps = {
  variant: 'info',
};

export const CaretContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TextContainer = styled.span`
  margin-left: 8px;
`;
