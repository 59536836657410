import { useEffect } from 'react';
import { useProfileDataType } from 'types/ProfileData.types';
import useFetch from '../hooks/useFetch';

const { REACT_APP_BASE_URL } = process.env;

function getURL(
  page: number,
  limit: number,
  userID: number,
  range?: string,
  startDate?: Date,
  endDate?: Date,
  customRangeSelected?: boolean,
  registerID?: string,
  searchTerm?: string,
  currentTab?: string,
  orderOrientation?: string,
) {
  const queryType = customRangeSelected
    ? `&start=${startDate?.getTime()}&end=${endDate?.getTime()}`
    : '';
  const queryID = registerID || userID;
  const kudosURL = `${REACT_APP_BASE_URL}/kudos/profile?id=${queryID}&page=${page}&limit=${limit}&kudosType=${currentTab}&range=${range}&searchTerm=${searchTerm}${queryType}&orderOrientation=${orderOrientation}`;
  return kudosURL;
}

export function useProfileData(
  page: number,
  limit: number,
  userEmail?: string,
  range?: string,
  startDate?: Date,
  endDate?: Date,
  customRangeSelected?: boolean,
  registerID?: string,
  searchTerm?: string,
  currentTab?: string,
  orderOrientation?: string,
): useProfileDataType {
  const {
    dataAPI: userData,
    isLoading: isLoadingUser,
    error: userError,
    fetchApi: fetchUser,
  } = useFetch();

  const {
    dataAPI: profileData,
    isLoading: isLoadingProfile,
    error: profileError,
    fetchApi: fetchProfile,
  } = useFetch();

  const {
    dataAPI: kudosGiven,
    error: givenError,
    fetchApi: fetchGiven,
  } = useFetch();

  const {
    dataAPI: kudosReceived,
    error: receivedError,
    fetchApi: fetchReceived,
  } = useFetch();

  const { user: { id: userID = 0, image = '', name = '' } = {} } = userData;
  const { kudos: profileItems = [], total = 0 } = profileData;
  const user = { giverName: name, picture: image };
  const { total: totalGivers = 0 } = kudosGiven;
  const { total: totalReceivers = 0 } = kudosReceived;

  useEffect(() => {
    const getUserID = async () => {
      const queryParams = registerID
        ? `id=${registerID}`
        : `email=${userEmail}`;
      const userIdUrl = `${REACT_APP_BASE_URL}/user?${queryParams}`;
      await fetchUser(userIdUrl);
    };
    getUserID();
  }, [userEmail, registerID]);

  useEffect(() => {
    const getProfileInfo = async () => {
      if (userID || registerID) {
        const kudosUrl = getURL(
          page,
          limit,
          userID,
          range,
          startDate,
          endDate,
          customRangeSelected,
          registerID,
          searchTerm,
          currentTab,
          orderOrientation,
        );
        await fetchProfile(kudosUrl);
      }
    };
    if (!userError && !profileError) getProfileInfo();
  }, [
    range,
    userID,
    page,
    limit,
    userError,
    profileError,
    searchTerm,
    currentTab,
    orderOrientation,
  ]);

  useEffect(() => {
    const getKudosGiven = async () => {
      const queryID = registerID || userID;
      if (queryID) {
        const kudosGivenURL = `${REACT_APP_BASE_URL}/kudos/profile?id=${queryID}&kudosType=given`;
        await fetchGiven(kudosGivenURL);
      }
    };
    if (!userError && !givenError) getKudosGiven();
  }, [userID, userError, givenError]);

  useEffect(() => {
    const getKudosReceived = async () => {
      const queryID = registerID || userID;
      if (queryID) {
        const kudosReceivedURL = `${REACT_APP_BASE_URL}/kudos/profile?id=${queryID}&kudosType=received`;
        await fetchReceived(kudosReceivedURL);
      }
    };
    if (!userError && !receivedError) getKudosReceived();
  }, [userID, userError, receivedError]);

  return {
    loading: isLoadingUser || isLoadingProfile,
    profileItems,
    total,
    totalReceivers,
    totalGivers,
    user,
  };
}
