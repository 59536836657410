import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Card, States, Box, Button } from '@wizeline/patio-ui';
import {
  Dropdown,
  LeaderboardItem,
  Paginator,
  PaginatorInfo,
  SearchInput,
} from 'components/UI';
import ExportModal from 'components/ExportModal';
import { Option } from 'components/UI/Dropdown/Dropdown.types';
import {
  PaginatorInfoWrapper,
  PaginatoWrapper,
  ExportButton,
} from './Leaderboard.styles';

import { LeaderboardProps } from './Leaderboard.types';

const regionOptions = [
  { text: 'All', value: '' },
  { text: 'APAC', value: 'Asia/Bangkok,Asia/Ho_Chi_Minh' },
];

const Leaderboard: FunctionComponent<LeaderboardProps> = ({
  leaderboardItems = [],
  leaderboardPage,
  totalItems,
  onLoadMoreData,
  actionText = 'Kudos',
  itemsPerPage = 20,
  loading = false,
  firstRun = false,
  resetSearch = false,
  error,
  searchBar = true,
}) => {
  const [isOpenModal, setModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchRegion, setSearchRegion] = useState('');

  const toggleModal = () => {
    setModalOpen(!isOpenModal);
  };

  const searchInData = useCallback(
    async (search: string) => {
      setSearchTerm(search);
      await onLoadMoreData(1, itemsPerPage, searchRegion, search);
    },
    [itemsPerPage, onLoadMoreData],
  );
  const onSelectRegion = useCallback(
    async (option: Option) => {
      const { value: region } = option;
      setSearchRegion(region);
      await onLoadMoreData(1, itemsPerPage, region, searchTerm);
    },
    [itemsPerPage, onLoadMoreData],
  );

  const setPage = async (pageNumber: number) => {
    await onLoadMoreData(pageNumber, itemsPerPage);
  };

  useEffect(() => {
    if (!firstRun) {
      onLoadMoreData(1, itemsPerPage, '');
    }
  }, []);

  const renderLeaderboarItems = () => {
    const items = leaderboardItems.map((person, index) => {
      return (
        <LeaderboardItem
          key={`${actionText}-${person.id}`}
          loading={loading}
          order={person.ranking}
          registerID={person.id}
          name={person.name}
          avatarSrc={person.image}
          quantity={person.kudos_count}
          action={actionText}
          isFirstItem={index === 0}
        />
      );
    });
    return items;
  };

  const stateSkeletonLoading = () => {
    const ghostLiderboardItems = [];
    for (let index = 0; index < 7; index++) {
      ghostLiderboardItems.push(
        <LeaderboardItem
          key={index}
          order={index}
          registerID={0}
          avatarSrc=""
          name=""
          quantity={0}
          action=""
          loading
          isFirstItem={index === 0}
        />,
      );
    }
    return ghostLiderboardItems;
  };

  const handleEmptyLeaderboard = () => {
    if (firstRun) {
      return stateSkeletonLoading();
    }
    if (error) {
      return <States state="error" />;
    }
    return <States state={searchTerm.length === 0 ? 'blank' : 'emptySearch'} />;
  };

  return (
    <>
      <Box>
        {searchBar && (
          <Box
            display="flex"
            flexDirection={['column', 'row']}
            justifyContent="space-between"
            alignItems="center"
            marginY={['16px', '32px']}
            marginX="0px">
            <Box display="flex">
              <SearchInput
                submitHandler={searchInData}
                placeholder="Search name"
                resetSearch={resetSearch}
              />
              <Box minWidth={100} maxWidth={200} ml={2}>
                <Dropdown
                  title="Region"
                  defaultValue=""
                  options={regionOptions}
                  onChange={onSelectRegion}
                />
              </Box>
            </Box>

            <ExportButton>
              <Button variant="link" onClick={toggleModal}>
                Export Data
              </Button>
            </ExportButton>
          </Box>
        )}
        <ExportModal isOpen={isOpenModal} onCloseModal={toggleModal} />
        <Card boxShadow="base" padding="24px" borderRadius={3}>
          {leaderboardItems.length < 1
            ? handleEmptyLeaderboard()
            : renderLeaderboarItems()}
        </Card>
      </Box>
      {totalItems > 0 && (
        <Box>
          <PaginatorInfoWrapper>
            <PaginatorInfo
              pageSize={itemsPerPage}
              totalItems={totalItems}
              currentPage={leaderboardPage}
            />
          </PaginatorInfoWrapper>
          {totalItems > itemsPerPage && (
            <PaginatoWrapper>
              <Paginator
                pageSize={itemsPerPage}
                totalItems={totalItems}
                initialPage={1}
                onSelectPage={setPage}
                pageSync={leaderboardPage}
              />
            </PaginatoWrapper>
          )}
        </Box>
      )}
    </>
  );
};

export default Leaderboard;
