import { lazy, Suspense, useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import ProtectedRoute from 'auth/protected-route';
import { LoadingPage } from 'components/UI';
import TimeLine from 'pages/Timeline';
import HomePage from 'pages/Homepage';
import SearchPage from 'pages/Search';
import ValuesPage from 'pages/Values';
import ProfilePage from 'pages/Profile';
import Dashboard from 'pages/Dashboard';
import CallbackRoute from 'auth/callback';
import { useAuth0 } from '@auth0/auth0-react';

import useFetch from './hooks/useFetch';

const PageLayout = lazy(() => import('./components/Layout/PageLayout'));

const { REACT_APP_BASE_URL } = process.env;

const AppRouter: React.FunctionComponent = () => {
  const { user } = useAuth0();
  const { dataAPI: userData, fetchApi: fetchUser } = useFetch();

  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  useEffect(() => {
    if (user) {
      const userEmail = user.email;

      const getProfile = async () => {
        const userProfileUrl = `${REACT_APP_BASE_URL}/user?email=${userEmail}`;
        await fetchUser(userProfileUrl);
      };

      getProfile();
    }
  }, [user]);

  useEffect(() => {
    if (Object.keys(userData).length > 0) {
      const userRole = userData.user.role_name;
      setIsAdmin(userRole === 'admin');
    }
  }, [userData]);

  return (
    <Suspense fallback={<LoadingPage />}>
      <Router>
        <PageLayout isAdmin={isAdmin}>
          <Switch>
            <ProtectedRoute exact path="/kudos" component={HomePage} />
            <ProtectedRoute exact path="/values" component={ValuesPage} />
            <ProtectedRoute exact path="/timeline" component={TimeLine} />
            <ProtectedRoute
              exact
              path="/search/:searchTerm?"
              component={SearchPage}
            />
            <ProtectedRoute
              exact
              path="/profile/:id?"
              component={ProfilePage}
            />
            {isAdmin && (
              <ProtectedRoute exact path="/dashboard" component={Dashboard} />
            )}
            <Route exact path="/callback" component={CallbackRoute} />
            <Redirect to="/kudos" />
          </Switch>
        </PageLayout>
      </Router>
    </Suspense>
  );
};

export default AppRouter;
