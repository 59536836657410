import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button } from '@wizeline/patio-ui';
import { useHistory } from 'react-router';
import PATIO_LOGO from 'styles/imgs/patio_logo.svg';
import { SearchInput } from 'components/UI';
import {
  StyledNav,
  StyledAviContainer,
  StyledLogo,
  StyledRightContainer,
} from './Navbar.styles';
import Dropdown from './Dropdown/Dropdown';
import { NavbarProps } from './Navbar.types';

const redirectFAQPage = () => {
  window.open(
    'https://docs.google.com/document/d/1ERaCnbVx8v6NR2217UZca0vH60UydFBticVI7Ue89os/edit#heading=h.ym3s9kodr377',
    '_blank',
    'noopener,noreferrer,resizable',
  );
};

const Navbar: FunctionComponent<NavbarProps> = ({
  username,
  avatarSrc,
  isAdmin,
  isDashboard,
  setIsDashboard,
}) => {
  const history = useHistory();
  const [resetSearch, setResetSearch] = useState(false);

  function handleSearchTermChange(search: string) {
    setResetSearch(false);
    if (search !== '') {
      history.push(`/search/${search}`);
    }
  }

  useEffect(() => {
    const { pathname } = history.location;
    if (!pathname.startsWith('/search')) {
      setResetSearch(true);
    }
  }, [history.location.pathname]);

  return (
    <StyledNav>
      <StyledAviContainer>
        <StyledLogo
          src={PATIO_LOGO}
          alt="Patio Logo"
          onClick={() => {
            if (isDashboard) {
              history.push('/dashboard');
            } else {
              history.push('/');
            }
          }}
        />
      </StyledAviContainer>
      <StyledRightContainer>
        <SearchInput
          submitHandler={handleSearchTermChange}
          placeholder="Search users or messages"
          resetSearch={resetSearch}
        />
        <Button variant="link" onClick={redirectFAQPage}>
          FAQ
        </Button>
        <Dropdown
          username={username}
          avatarSrc={avatarSrc}
          isAdmin={isAdmin}
          isDashboard={isDashboard}
          setIsDashboard={setIsDashboard}
        />
      </StyledRightContainer>
    </StyledNav>
  );
};

export default Navbar;
