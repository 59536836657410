import * as React from 'react';
import { Box, Card, Loader } from '@wizeline/patio-ui';

const ProfileKudoSkeleton: React.FunctionComponent = () => {
  return (
    <Card
      boxShadow="base"
      width="100%"
      display="flex"
      flexDirection="column"
      padding="24px"
      marginY="12px">
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center">
        <Box display="flex" alignItems="center">
          <Loader variant="circle" width={40} height={35} />
          <Loader variant="rect" width={150} height={35} />
        </Box>
        <Box display="flex" alignItems="center">
          <Loader variant="rect" width={80} height={35} />
        </Box>
      </Box>
      <Box marginY="8px">
        <Loader variant="rect" height={30} />
      </Box>
    </Card>
  );
};

export default ProfileKudoSkeleton;
