import styled from 'styled-components';
import { Box, Typography } from '@wizeline/patio-ui';
import { device } from 'constants/breakpoints';

export const PaginatorInfoWrapper = styled(Box)`
  float: left;
  font-weight: 600;
  margin: 32px auto 80px 16px;
`;

export const PaginatoWrapper = styled(Box)`
  float: right;
  margin: 32px 16px 80px auto;
`;

export const LeaderboardHeader = styled(Typography)`
  font-family: Inter;
  font-size: 32px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.gray[90]};
  margin: 0;
  padding: 0;
  text-align: center;

  @media ${device.tablet} {
    font-size: 36px;
  }
`;

export const TypographyEmptyState = styled(Typography)`
  text-align: center;
`;

export const LeaderboardWrapper = styled(Box)`
  padding: 16px 32px;
  width: 100%;
  min-height: calc(100vh - 64px);
  background-color: ${({ theme }) => theme.colors.gray[10]};

  @media ${device.mobileL} {
    padding: 32px 64px;
  }

  @media ${device.tablet} {
    padding: 64px 140px;
  }
`;

export const ExportButton = styled.div`
  & > button {
    padding: 0 12px;
  }
`;
