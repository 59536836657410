export const months = [
  {
    text: 'January',
    value: 'Jan',
  },
  {
    text: 'February',
    value: 'Feb',
  },
  {
    text: 'March',
    value: 'Mar',
  },
  {
    text: 'April',
    value: 'Apr',
  },
  {
    text: 'May',
    value: 'May',
  },
  {
    text: 'June',
    value: 'Jun',
  },
  {
    text: 'July',
    value: 'Jul',
  },
  {
    text: 'August',
    value: 'Aug',
  },
  {
    text: 'September',
    value: 'Sep',
  },
  {
    text: 'October',
    value: 'Oct',
  },
  {
    text: 'November',
    value: 'Nov',
  },
  {
    text: 'December',
    value: 'Dec',
  },
];

const currentYear = new Date().getFullYear();
const startYear = 2020;

export const years = Array.from({ length: currentYear - startYear + 1 }).map(
  (_, index) => ({
    text: String(currentYear - index),
    value: String(currentYear - index),
  }),
);
