/* eslint-disable @typescript-eslint/no-unused-vars */
// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { States, Box, Stack } from '@wizeline/patio-ui';
import { useTotalKudosData } from 'lib/useTotalKudosData';
import TimelineKudoItem from 'components/UI/TimelineKudoItem';
import ProfileKudosListSkeleton from 'components/Profile/ProfileKudosListSkeleton';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  TypographyEmptyState,
  LeaderboardHeader,
} from '../../components/Leaderboard/Leaderboard.styles';

const TimeLine: React.FunctionComponent = () => {
  const { profileItems, loadMore } = useTotalKudosData();
  const [range, setRange] = useState('');
  const [page, setPage] = useState(1);
  const [totalKudos, setTotalKudos] = useState(0);
  const emptyStateTitle = 'No kudos yet!';

  if (!profileItems) {
    return <ProfileKudosListSkeleton />;
  }
  const renderProfileKudos = () => {
    const items = profileItems.map((kudo) => {
      return <TimelineKudoItem key={kudo.message_id} kudo={kudo} />;
    });
    return items.length ? items : <ProfileKudosListSkeleton />;
  };
  return (
    <>
      <InfiniteScroll
        dataLength={profileItems.length}
        hasMore
        next={loadMore}
        loader={<ProfileKudosListSkeleton />}>
        <Box
          display="flex"
          flexDirection={['column', 'row']}
          justifyContent={['center', 'space-between']}
          alignItems={['center', 'flex-end']}>
          <LeaderboardHeader>Timeline</LeaderboardHeader>
        </Box>
        <Stack align="center">{renderProfileKudos()}</Stack>
      </InfiniteScroll>
    </>
  );
};

export default TimeLine;
