import { FunctionComponent, useState, useEffect } from 'react';
import PageLink from './PageLink';
import { PaginatorWrapper } from './Paginator.styles';
import { PaginatorProps } from './Paginator.types';

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

const range = (from: number, to: number, step = 1) => {
  let i = from;
  const result = [];
  while (i <= to) {
    result.push(i);
    i += step;
  }

  return result;
};

const Paginator: FunctionComponent<PaginatorProps> = (props) => {
  const {
    pageSize,
    totalItems,
    initialPage = 1,
    pageNeighbours = 1,
    truncator = '...',
    onSelectPage = (page) => page,
    pageSync = -1,
  } = props;
  const totalPages = Math.ceil(totalItems / pageSize);

  const getValidInitialPage = () => {
    if (pageSync > -1) {
      return pageSync;
    }
    if (initialPage <= 0) {
      return 1;
    }
    return Math.min(initialPage, totalPages);
  };

  const validInitialPage = getValidInitialPage();
  const [currentPage, setCurrentPage] = useState(validInitialPage);

  useEffect(() => {
    if (pageSync > -1 && pageSync !== currentPage) {
      setCurrentPage(pageSync);
    }
  }, [pageSync, currentPage]);

  const fetchPageNumbers = () => {
    const totalNumbers = pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbours);
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);

      let pages: any[] = range(startPage, endPage);

      const hasLeftSpill = startPage > 2;
      const hasRightSpill = totalPages - endPage > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(startPage - spillOffset, startPage - 1);
          pages = [LEFT_PAGE, ...extraPages, ...pages];
          break;
        }

        case !hasLeftSpill && hasRightSpill: {
          const extraPages = range(endPage + 1, endPage + spillOffset);
          pages = [...pages, ...extraPages, RIGHT_PAGE];
          break;
        }

        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
          break;
        }
      }

      return [1, ...pages, totalPages];
    }

    return range(1, totalPages);
  };

  const pageNumbers = fetchPageNumbers();

  const selectPage = (pageNumber: number) => {
    onSelectPage(pageNumber);
  };

  const selectPreviousPage = () => {
    const previousPage = currentPage - 1;
    selectPage(previousPage);
  };

  const selectNextPage = () => {
    const nextPage = currentPage + 1;
    selectPage(nextPage);
  };

  const selectLeft = () => {
    const leftPage = currentPage - pageNeighbours * 2 - 1;
    selectPage(leftPage);
  };

  const selectRight = () => {
    const rightPage = currentPage + pageNeighbours * 2 + 1;
    selectPage(rightPage);
  };

  return (
    <PaginatorWrapper data-testid="paginator-test">
      <PageLink isDisabled={currentPage === 1} onClick={selectPreviousPage}>
        &lsaquo;
      </PageLink>

      {pageNumbers.map((number) => {
        const key = Math.random().toString(36).substr(2, 6);

        if (number === LEFT_PAGE) {
          return (
            <PageLink key={key} onClick={() => selectLeft()}>
              {truncator}
            </PageLink>
          );
        }

        if (number === RIGHT_PAGE) {
          return (
            <PageLink key={key} onClick={() => selectRight()}>
              {truncator}
            </PageLink>
          );
        }

        return (
          <PageLink
            key={key}
            onClick={() => selectPage(number)}
            isActive={currentPage === number}>
            {number}
          </PageLink>
        );
      })}

      <PageLink
        isDisabled={currentPage === totalPages}
        onClick={selectNextPage}>
        &rsaquo;
      </PageLink>
    </PaginatorWrapper>
  );
};

export default Paginator;
