import React from 'react';

const Tomato: React.FunctionComponent = (props: any) => {
  return (
    <svg
      width="35px"
      height="35px"
      viewBox="0 0 35 35"
      version="1.1"
      {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-553.000000, -290.000000)" fill="#F26178">
          <path d="M556.813842,325 C554.707523,325 553,323.292477 553,321.186158 L553,293.813842 C553,291.707523 554.707523,290 556.813842,290 L584.186158,290 C586.292477,290 588,291.707523 588,293.813842 L588,321.271815 C588,323.331093 586.331093,325 584.271815,325 L556.813842,325 Z" />
        </g>
      </g>
    </svg>
  );
};

export default Tomato;
