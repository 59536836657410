import React from 'react';

const Circle: React.FunctionComponent = (props) => {
  return (
    <svg
      width="72px"
      height="72px"
      viewBox="0 0 72 72"
      version="1.1"
      {...props}>
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        fillOpacity="0.7">
        <g transform="translate(-588.000000, -281.000000)" fill="#008098">
          <path d="M660,317 C660,336.882001 643.882719,353 624,353 C604.117999,353 588,336.882001 588,317 C588,297.117999 604.117999,281 624,281 C643.882719,281 660,297.117999 660,317" />
        </g>
      </g>
    </svg>
  );
};

export default Circle;
