import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Box, Card, States } from '@wizeline/patio-ui';
import {
  Tabs,
  TabItem,
  LeaderboardItem,
  PaginatorInfo,
  Paginator,
} from 'components/UI';
import ProfileKudoItem from 'components/UI/ProfileKudoItem';
import {
  PaginatoWrapper,
  PaginatorInfoWrapper,
  TypographyEmptyState,
} from 'components/Leaderboard/Leaderboard.styles';
import ProfileKudosListSkeleton from 'components/Profile/ProfileKudosListSkeleton';
import { SearchResult } from 'components/Search/Search.styles';
import { useSearchData } from '../../lib/useSearchData';

type pageParams = {
  searchTerm: string;
};

const ITEMS_PER_PAGE = 8;

const SearchPage: React.FunctionComponent = () => {
  const [currentTab, setCurrentTab] = useState('users');
  const [page, setPage] = useState(1);
  const { searchTerm = '' } = useParams<pageParams>();
  const [totalKudos, setTotalKudos] = useState(0);
  const { loading, searchItems, total } = useSearchData(
    page,
    ITEMS_PER_PAGE,
    searchTerm,
    currentTab,
  );

  useEffect(() => {
    if (searchItems) setTotalKudos(total);
  }, [searchItems]);

  const emptyStateTitle = 'No kudos yet!';
  const emptyStateBodyYours = `You haven't ${currentTab} kudos, but you can take the first step by recognizing your colleagues' efforts.`;
  const emptyStateBodyOthers = `This person hasn't ${currentTab} kudos, but you can take the first step by recognizing them.`;

  if (searchItems) {
    const renderSearchItems = () => {
      const items = searchItems.map((item: any, index: number) => {
        if (currentTab === 'users') {
          return (
            <LeaderboardItem
              order={0}
              avatarSrc={item.image}
              name={item.name}
              quantity={0}
              action="kudos"
              isFirstItem={index === 0}
              loading={loading}
              registerID={item.id}
            />
          );
        }
        return <ProfileKudoItem kudo={item} />;
      });

      return items.length ? (
        items
      ) : (
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          padding={5}>
          <TypographyEmptyState variant="headingSmall">
            {emptyStateTitle}
          </TypographyEmptyState>
          <TypographyEmptyState variant="bodyRegular">
            {currentTab === 'users'
              ? emptyStateBodyOthers
              : emptyStateBodyYours}
          </TypographyEmptyState>
        </Box>
      );
    };

    return (
      <>
        <Box
          display="flex"
          flexDirection={['column', 'row']}
          justifyContent={['center', 'space-between']}
          alignItems={['center', 'flex-end']}>
          <SearchResult>Search Results: {searchTerm}</SearchResult>
        </Box>
        <Tabs
          defaultKey="users"
          onActiveChange={(tabKey) => {
            setCurrentTab(tabKey);
            setPage(1);
            searchItems.length = 0;
          }}>
          <TabItem tabKey="users" text="Users" />
          <TabItem tabKey="messages" text="Messages" />
        </Tabs>
        {!loading ? (
          <>
            <>
              {currentTab === 'users' ? (
                <Card boxShadow="base" padding="24px" borderRadius={3}>
                  {renderSearchItems()}
                </Card>
              ) : (
                <>{renderSearchItems()}</>
              )}
            </>
            {totalKudos > 0 && (
              <Box>
                <PaginatorInfoWrapper>
                  <PaginatorInfo
                    pageSize={ITEMS_PER_PAGE}
                    totalItems={totalKudos}
                    currentPage={page}
                  />
                </PaginatorInfoWrapper>
                {totalKudos > ITEMS_PER_PAGE && (
                  <PaginatoWrapper>
                    <Paginator
                      pageSize={ITEMS_PER_PAGE}
                      totalItems={totalKudos}
                      initialPage={1}
                      onSelectPage={setPage}
                      pageSync={page}
                    />
                  </PaginatoWrapper>
                )}
              </Box>
            )}
          </>
        ) : (
          <ProfileKudosListSkeleton />
        )}
      </>
    );
  }

  return <States state="error" />;
};

export default SearchPage;
