import * as React from 'react';
import { Loader, Box, Stack } from '@wizeline/patio-ui';

const ProfileSkeletonHeader: React.FunctionComponent = () => {
  return (
    <Box paddingTop="64px">
      <Stack align="left">
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box marginBottom="10px">
            <Loader variant="circle" width={120} height={120} />
          </Box>
          <Box marginLeft="36px">
            <Loader variant="rect" width={250} height={40} />
          </Box>
        </Box>
      </Stack>
      <Stack align="right">
        <Box alignItems="right" justifyContent="right" marginTop="8px">
          <Loader variant="rect" width={200} height={40} />
        </Box>
      </Stack>
    </Box>
  );
};

export default ProfileSkeletonHeader;
