import styled from 'styled-components';

export const StyledNav = styled.nav`
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 8px 2px 20px 0px rgba(0, 0, 0, 0.08);
  width: 100%;
  height: 64px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  z-index: 1;
`;

export const StyledAviContainer = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.gray[60]};
  cursor: pointer;
`;

export const StyledRightContainer = styled.div`
  display: flex;
  margin-right: 4%;
  align-items: center;

  & > button {
    padding: 0;
  }
`;

export const StyledLogo = styled.img.attrs(() => ({
  width: 92,
  height: 32,
}))``;
