import { FunctionComponent, useState, useEffect, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router';
import { Avatar } from '@wizeline/patio-ui';
import ARROW_ICON from 'styles/imgs/keyboard_arrow_down-black-18dp.svg';
import {
  DropdownLink,
  DropdownListContainer,
  DropdownContainer,
  DropdownButton,
  DropdownUsernameContainer,
  DropdownArrowImg,
} from './Dropdown.styles';
import { DropdownProps } from './Dropdown.types';

const Dropdown: FunctionComponent<DropdownProps> = ({
  username,
  avatarSrc,
  isAdmin,
  isDashboard,
  setIsDashboard,
}) => {
  const [isOpen, setOpen] = useState(false);
  const { logout } = useAuth0();
  const history = useHistory();
  let component;
  const containerRef = useRef<HTMLDivElement>(null);
  const handleOutsideClick = (event: MouseEvent) => {
    const node = containerRef.current;
    if (node && !node.contains(event.target as HTMLElement)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick, false);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick, false);
    };
  }, []);

  const toggleDropdown = () => {
    setOpen(!isOpen);
  };

  function handleClickToProfile() {
    history.push({
      pathname: '/profile',
    });
    toggleDropdown();
  }

  function handleClickToDashboard() {
    setIsDashboard(true);
    history.push({
      pathname: '/dashboard',
    });
    toggleDropdown();
  }

  function handleClickToLeaderboards() {
    setIsDashboard(false);
    history.push({
      pathname: '/kudos',
    });
    toggleDropdown();
  }

  if (isDashboard) {
    component = (
      <DropdownLink onClick={handleClickToLeaderboards}>
        Leaderboards
      </DropdownLink>
    );
  } else {
    component = (
      <DropdownLink onClick={handleClickToDashboard}>Dashboard</DropdownLink>
    );
  }
  return (
    <DropdownContainer ref={containerRef}>
      {!!username && !!avatarSrc && (
        <DropdownButton
          type="button"
          onClick={toggleDropdown}
          data-testid="dropdown-button">
          <Avatar sizes="small" src={avatarSrc} />
          <DropdownUsernameContainer>{username}</DropdownUsernameContainer>
          <DropdownArrowImg src={ARROW_ICON} />
        </DropdownButton>
      )}
      <DropdownListContainer isOpen={isOpen} data-testid="dropdown-menu">
        {isAdmin && component}
        <DropdownLink onClick={handleClickToProfile}>Profile</DropdownLink>
        <DropdownLink
          onClick={() => logout({ returnTo: window.location.origin })}>
          Log out
        </DropdownLink>
      </DropdownListContainer>
    </DropdownContainer>
  );
};

export default Dropdown;
