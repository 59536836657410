import { useAuth0 } from '@auth0/auth0-react';
import { Box, Card, Typography, Button } from '@wizeline/patio-ui';
import { Redirect } from 'react-router-dom';
import PATIO_LOGO from 'styles/imgs/patio.svg';
import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';
import theme from 'styles/theme';

export const Logo = styled.img<SpaceProps>`
  ${space}
`;

interface ErrorPageProps {
  onRedirect: ({ returnTo }: { returnTo: string }) => void;
}

const ErrorPage = ({ onRedirect }: ErrorPageProps) => {
  return (
    <Box
      padding={4}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center">
      <Logo
        alt="Patio Logo"
        src={PATIO_LOGO}
        width={92}
        height={32}
        marginBottom={4}
      />
      <Card
        borderRadius={3}
        boxShadow="base"
        marginY={2}
        paddingX={6}
        paddingY={4}
        display="flex"
        alignContent="center"
        flexDirection="column"
        alignItems="center">
        <Typography variant="headingSmall">Oops!</Typography>
        <Typography color={theme.colors.gray[90]}>
          Please log in with your wizeline account
        </Typography>
        <Button
          variant="primary"
          type="button"
          style={{
            marginTop: '32px',
          }}
          onClick={() => onRedirect({ returnTo: window.location.origin })}>
          Go back
        </Button>
      </Card>
    </Box>
  );
};

const Callback: React.FunctionComponent = () => {
  const { isAuthenticated, isLoading, logout } = useAuth0();

  if (isLoading) {
    return null;
  }

  if (isAuthenticated) {
    const current = window.location?.pathname;
    return <Redirect push to={current} />;
  }

  return <ErrorPage onRedirect={logout} />;
};

export default Callback;
