export interface Config {
  [key: string]: string;
}

const getIsTest = () => process.env.REACT_APP_ENV === 'test';

const config = <Config>{
  AUTH0_CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID,
  AUTH0_SCOPE: process.env.REACT_APP_AUTH0_SCOPE,
  AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN,
  REDIRECT_URI: process.env.REACT_APP_REDIRECT_URI,
  POST_LOGOUT_REDIRECT_URI: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
  AUTH0_AUDIENCE: process.env.REACT_APP_AUTH0_AUDIENCE,
};

const testConfig = <Config>{
  AUTH0_CLIENT_ID: process.env.REACT_APP_CYPRESS_AUTH0_CLIENT_ID,
  AUTH0_SCOPE: process.env.REACT_APP_AUTH0_SCOPE,
  AUTH0_DOMAIN: process.env.REACT_APP_CYPRESS_AUTH0_DOMAIN,
  REDIRECT_URI: process.env.REACT_APP_REDIRECT_URI,
  POST_LOGOUT_REDIRECT_URI: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
  AUTH0_AUDIENCE: process.env.REACT_APP_CYPRESS_AUTH0_AUDIENCE,
};

export default getIsTest() ? testConfig : config;
