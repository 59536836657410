import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { createBrowserHistory } from 'history';

import config from '../lib/config';

const history = createBrowserHistory();

const onRedirectCallback: any = (appState: any) => {
  history.replace(appState?.returnTo || window.location.pathname);
};

const Auth0ProviderWithHistory: React.FunctionComponent = ({ children }) => (
  <Auth0Provider
    domain={config.AUTH0_DOMAIN}
    clientId={config.AUTH0_CLIENT_ID}
    audience={config.AUTH0_AUDIENCE}
    redirectUri={config.REDIRECT_URI}
    onRedirectCallback={onRedirectCallback}>
    {children}
  </Auth0Provider>
);

export default Auth0ProviderWithHistory;
