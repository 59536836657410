const theme = {
  space: [0, 4, 8, 16, 32, 64, 128, 140, 256, 512],
  colors: {
    transparent: 'transparent',

    black: '#000',
    white: '#fff',

    myColor: '#50B0C9',

    primary: {
      light: '#50B0C9',
      base: '#008098',
      dark: '#00536A',
      hover: '#00687c',
      active: '#004b59',
    },
    salmon: {
      light: '#C00000',
      base: '#ff8d6b',
      dark: '#c85e3f',
    },
    pavioletred: {
      light: '#FF93A7',
      base: '#f26178',
      dark: '#bb2c4c',
    },
    gray: {
      100: '#353C43',
      90: '#4C555F',
      80: '#626F7C',
      70: '#7B8896',
      60: '#B4BBC3',
      50: '#D0D5DA',
      40: '#EBEBEB',
      30: '#F2F4F5',
      20: '#F8F8FA',
      10: '#FBFBFC',
    },
    red: {
      100: '#fff5f5',
      200: '#fed7d7',
      300: '#feb2b2',
      400: '#fc8181',
      500: '#f56565',
      600: '#e53e3e',
      700: '#c53030',
      800: '#9b2c2c',
      900: '#742a2a',
    },
    orange: {
      100: '#fffaf0',
      200: '#feebc8',
      300: '#fbd38d',
      400: '#f6ad55',
      500: '#ed8936',
      600: '#dd6b20',
      700: '#c05621',
      800: '#9c4221',
      900: '#7b341e',
    },
    yellow: {
      100: '#fffff0',
      200: '#fefcbf',
      300: '#faf089',
      400: '#f6e05e',
      500: '#ecc94b',
      600: '#d69e2e',
      700: '#b7791f',
      800: '#975a16',
      900: '#744210',
    },
    green: {
      100: '#f0fff4',
      200: '#c6f6d5',
      300: '#9ae6b4',
      400: '#68d391',
      500: '#48bb78',
      600: '#38a169',
      700: '#2f855a',
      800: '#276749',
      900: '#22543d',
    },
    teal: {
      100: '#e6fffa',
      200: '#b2f5ea',
      300: '#81e6d9',
      400: '#4fd1c5',
      500: '#38b2ac',
      600: '#319795',
      700: '#2c7a7b',
      800: '#285e61',
      900: '#234e52',
    },
    blue: {
      100: '#ebf8ff',
      200: '#bee3f8',
      300: '#90cdf4',
      400: '#63b3ed',
      500: '#4299e1',
      600: '#3182ce',
      700: '#2b6cb0',
      800: '#2c5282',
      900: '#2a4365',
    },
    indigo: {
      100: '#ebf4ff',
      200: '#c3dafe',
      300: '#a3bffa',
      400: '#7f9cf5',
      500: '#667eea',
      600: '#5a67d8',
      700: '#4c51bf',
      800: '#434190',
      900: '#3c366b',
    },
    purple: {
      100: '#faf5ff',
      200: '#e9d8fd',
      300: '#d6bcfa',
      400: '#b794f4',
      500: '#9f7aea',
      600: '#805ad5',
      700: '#6b46c1',
      800: '#553c9a',
      900: '#44337a',
    },
    pink: {
      100: '#fff5f7',
      200: '#fed7e2',
      300: '#fbb6ce',
      400: '#f687b3',
      500: '#ed64a6',
      600: '#d53f8c',
      700: '#b83280',
      800: '#97266d',
      900: '#702459',
    },
    success: {
      light: '#c6e9e0',
      dark: '#407e6c',
    },
    info: {
      light: '#e4eef8',
      dark: '#4c759c',
    },
    error: {
      light: '#fde7ed',
      dark: '#ac6a7b',
    },
    warning: {
      light: '#fef6db',
      dark: '#93814a',
    },
    text: {
      subtitle: '#354144',
    },
  },
  radii: [0, 2, 4, 8],
  shadows: {
    small: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    medium: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
    large: '0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)',
  },
  buttons: {},
};

const buttonVariants = {
  primary: {
    color: theme.colors.white,
    backgroundColor: theme.colors.primary.dark,
  },
  secondary: {
    color: theme.colors.gray[80],
    backgroundColor: theme.colors.transparent,
    border: `1px solid ${theme.colors.gray[70]}`,
  },
  link: {
    color: theme.colors.primary.dark,
    backgroundColor: theme.colors.transparent,
  },
};

export default {
  ...theme,
  buttons: { ...buttonVariants },
};
