import { FunctionComponent } from 'react';
import styled from 'styled-components';

const Input = styled.input`
  width: 100%;
  height: 40px;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid rgb(125, 136, 149);
  color: ${(props) => props.theme.colors.gray[70]};
  font-family: 'Nunito Sans', sans-serif;

  &[type='text'] {
    padding-left: 32px;
  }
`;

interface TextFieldProps {
  /** Sets the value for the input
   * @default empty
   */
  value?: string;
  /** Sets the placeholder for the input
   * @default empty
   */
  placeholder?: string;
  /** Defines the callback for the onChange event
   * @default null
   */
  changeHandler: React.ChangeEventHandler<HTMLInputElement>;
}

const TextField: FunctionComponent<TextFieldProps> = ({
  value,
  placeholder,
  changeHandler,
}) => (
  <Input
    type="text"
    placeholder={placeholder}
    value={value}
    onChange={changeHandler}
  />
);

export default TextField;
