import React, { useState } from 'react';
import { Loader, AvatarDefault } from '@wizeline/patio-ui';
import { AvatarProps, AvatarSizes } from './Avatar.types';
import StyledAvatar from './Avatar.styles';
import BoxImageOnTo from './AvatarOnTo.Styles';

const size: { [key in AvatarSizes]: number } = {
  small: 40,
  medium: 48,
  large: 120,
};

const Avatar: React.FC<AvatarProps> = (props) => {
  const { sizes = 'large', src } = props;
  const avatarSize = size[sizes];
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  if (!src || error) {
    return (
      <BoxImageOnTo sizes={sizes}>
        <AvatarDefault width={`${avatarSize}`} height={`${avatarSize}`} />
      </BoxImageOnTo>
    );
  }

  return (
    <BoxImageOnTo sizes={sizes}>
      <StyledAvatar
        style={{ display: loading ? 'none' : 'block' }}
        sizes={sizes}
        src={src}
        alt="avatar-image"
        onLoad={() => setLoading(false)}
        onError={() => setError(true)}
        width={avatarSize}
        height={avatarSize}
      />
      {loading && (
        <div data-testid="avatar-loader">
          <Loader variant="circle" width={avatarSize} height={avatarSize} />
        </div>
      )}
    </BoxImageOnTo>
  );
};

export default Avatar;
