import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { PatioUIProvider } from '@wizeline/patio-ui';
import Auth0ProviderWithHistory from 'auth/auth0-provider-with-history';
import GlobalStyles from './styles/global';
import App from './App';
import reportWebVitals from './reportWebVitals';
import theme from './styles/theme';

ReactDOM.render(
  <React.StrictMode>
    <Auth0ProviderWithHistory>
      <PatioUIProvider>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <App />
        </ThemeProvider>
      </PatioUIProvider>
    </Auth0ProviderWithHistory>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line no-console
  reportWebVitals(console.log);
}
