import styled from 'styled-components';
import { AvatarSizes } from './Avatar.types';

const size: { [key in AvatarSizes]: string } = {
  small: '40px',
  medium: '48px',
  large: '120px',
};

const StyledAvatar = styled.img`
  object-fit: cover;
  border-radius: 50%;
  height: ${({ sizes = 'large' }: { sizes: AvatarSizes }): string =>
    size[sizes]};
  width: ${({ sizes = 'large' }: { sizes: AvatarSizes }): string =>
    size[sizes]};
  position: relative;
`;

export default StyledAvatar;
