import { useState } from 'react';
import useFetch from '../../src/hooks/useFetch';

interface LoadKudosDataHook {
  getURL: (
    pageNumber: number,
    limit: number,
    searchTerm: string,
    searchRegion: string,
    date: Date,
    endPoint: string,
    value?: string,
  ) => any;
  date: Date;
  endPoint: string;
  value?: string;
}

interface useLoadKudos {
  leaderboardItems: any;
  isLoading: boolean;
  page: number;
  onLoadMoreItems: any;
  leaderboardDate: Date;
  loadMonthData: any;
  resetSearch: boolean;
  error: Error;
}

export const useLoadKudosData = ({
  getURL,
  date: initialDate = new Date(),
  endPoint = 'receivers',
  value,
}: LoadKudosDataHook): useLoadKudos => {
  const [searchTerm, setSearchTerm] = useState('');
  const [resetSearch, setResetSearch] = useState(false);
  const [leaderboardDate, setLeaderboardDate] = useState(new Date(initialDate));
  const [page, setPage] = useState(1);
  const { dataAPI: leaderboardItems, isLoading, fetchApi, error } = useFetch();

  const loadMonthData = async (monthDate: Date, skipLoading?: boolean) => {
    if (skipLoading) {
      return setLeaderboardDate(monthDate);
    }
    const url = getURL(1, 20, '', '', monthDate, endPoint, value);
    await fetchApi(url);
    setSearchTerm('');
    setResetSearch(!resetSearch);
    setLeaderboardDate(monthDate);
    setPage(1);

    return null;
  };

  const onLoadMoreItems = async (
    pageNumber: number,
    limit: number,
    searchRegion: string,
    searchText?: string,
  ) => {
    let search = searchTerm;

    if (searchText !== undefined && searchText !== searchTerm) {
      search = searchText;
      setSearchTerm(searchText);
    }
    const url = getURL(
      pageNumber,
      limit,
      search,
      searchRegion,
      leaderboardDate,
      endPoint,
      value,
    );
    await fetchApi(url);
    setPage(pageNumber);
  };

  return {
    leaderboardItems,
    isLoading,
    page,
    onLoadMoreItems,
    leaderboardDate,
    loadMonthData,
    resetSearch,
    error,
  };
};
