import { Box, Card } from '@wizeline/patio-ui';
import { FunctionComponent } from 'react';
import { KudoCounterHeader, KudoCounterNumber } from './KudosCounters.styles';

function formatNumber(number: number | bigint) {
  return new Intl.NumberFormat().format(number);
}

const KudoCounter: FunctionComponent<{ name: string; number: number }> = ({
  name,
  number,
}) => {
  return (
    <Card
      boxShadow="base"
      width="250px"
      display="flex"
      flexDirection="column"
      padding="24px"
      alignItems="flex-start">
      <Box>
        <KudoCounterHeader>{name}</KudoCounterHeader>
      </Box>
      <Box marginTop={30}>
        <KudoCounterNumber>{formatNumber(number)}</KudoCounterNumber>
      </Box>
    </Card>
  );
};

export default KudoCounter;
