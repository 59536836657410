import styled from 'styled-components';
import { Typography } from '@wizeline/patio-ui';

export const KudoGiverHeader = styled(Typography)`
  font-weight: 600;
  font-size: 18px;
  margin-left: 8px;
  text-transform: capitalize;
`;

export const KudoGivenDate = styled(Typography)`
  color: ${(props) => props.theme.colors.gray[80]};
`;

export const KudoGiverHeaderWrapper = styled(Typography)`
  button {
    padding: 0 12px;
  }
`;
