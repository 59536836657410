import { useEffect, useState, FunctionComponent } from 'react';
import { saveAs } from 'file-saver';
import { utils, write, WorkBook } from 'xlsx';
import { Button } from '@wizeline/patio-ui';
import { Sheet, Props } from './ExportSpreadsheetButton.type';
import useFetch from '../../hooks/useFetch';

const { REACT_APP_BASE_URL } = process.env;
const INITIAL_PAGE = 1;
const NUM_ITEMS_LIMIT = 500;
const NUM_SHEETS_TO_EXPORT = 2;
const RECEIVERS = 'receivers';
const GIVERS = 'givers';
interface kudosReceiver {
  receivers: any;
  total: number;
}
interface kudosGiver {
  givers: any;
  total: number;
}
interface kudosSheet {
  name: string;
  data: any;
}

const getPage = (total: number, length: number): number => {
  const numTotalPages = Math.ceil(total / NUM_ITEMS_LIMIT);
  const numRemainingPages = Math.ceil((total - length) / NUM_ITEMS_LIMIT);
  return numRemainingPages > 0 ? numTotalPages - numRemainingPages + 1 : 0;
};

const getKudos = (rawKudos: any): any => {
  const kudos = rawKudos.map((kudo: any) => ({
    name: kudo.name,
    kudosCount: kudo.kudos_count,
  }));
  return kudos;
};

const ExportSpreadsheetButton: FunctionComponent<Props> = ({
  filename,
  month,
  year,
  children,
}) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const [kudosReceivers, setKudosReceivers] = useState<kudosReceiver>();
  const [kudosGivers, setKudosGivers] = useState<kudosGiver>();
  const [kudosSheets, setKudosSheets] = useState<kudosSheet[]>([]);
  const { dataAPI, error, fetchApi } = useFetch();

  const createWorkbook = () => {
    return utils.book_new();
  };

  const addSheets = (workBook: WorkBook, sheets: Sheet[]) => {
    sheets.forEach((sheet: Sheet) => {
      const sheetData = utils.json_to_sheet(sheet.data);
      utils.book_append_sheet(workBook, sheetData, sheet.name);
    });
  };

  const saveWorkbook = (workBook: WorkBook, name: string) => {
    const workbookBuffer = write(workBook, {
      bookType: 'xlsx',
      type: 'array',
    });
    const fileData = new Blob([workbookBuffer], { type: fileType });
    saveAs(fileData, name);
  };

  const saveKudosSheets = (rawKudos: any, endPoint: string) => {
    const kudosData = getKudos(rawKudos);
    setKudosSheets([...kudosSheets, { name: endPoint, data: kudosData }]);
  };

  const fetchKudos = async (page: number, endPoint: string) => {
    const url = `${REACT_APP_BASE_URL}/kudos/${endPoint}?month=${month}&year=${year}&page=${page}&limit=${NUM_ITEMS_LIMIT}`;
    await fetchApi(url);
  };

  const exportFile = async () => {
    await fetchKudos(INITIAL_PAGE, RECEIVERS);
    await fetchKudos(INITIAL_PAGE, GIVERS);
  };

  useEffect(() => {
    const getDataAPI = async () => {
      if (Object.prototype.hasOwnProperty.call(dataAPI, RECEIVERS)) {
        setKudosReceivers({
          total: dataAPI.total,
          receivers: kudosReceivers
            ? [...kudosReceivers.receivers, ...dataAPI.receivers]
            : [...dataAPI.receivers],
        });
      } else if (Object.prototype.hasOwnProperty.call(dataAPI, GIVERS)) {
        setKudosGivers({
          total: dataAPI.total,
          givers: kudosGivers
            ? [...kudosGivers.givers, ...dataAPI.givers]
            : [...dataAPI.givers],
        });
      }
    };
    if (!error) getDataAPI();
  }, [dataAPI, error]);

  useEffect(() => {
    const getReceiverSheet = async () => {
      const receivers = (kudosReceivers && kudosReceivers.receivers) || [];
      const receiversTotal = (kudosReceivers && kudosReceivers.total) || 0;
      const page = getPage(receiversTotal, receivers.length);

      if (page) {
        await fetchKudos(page, RECEIVERS);
      } else {
        saveKudosSheets(receivers, RECEIVERS);
      }
    };
    if (kudosReceivers) getReceiverSheet();
  }, [kudosReceivers]);

  useEffect(() => {
    const getGiverSheet = async () => {
      const givers = (kudosGivers && kudosGivers.givers) || [];
      const giversTotal = (kudosGivers && kudosGivers.total) || 0;
      const page = getPage(giversTotal, givers.length);

      if (page) {
        await fetchKudos(page, GIVERS);
      } else {
        saveKudosSheets(givers, GIVERS);
      }
    };
    if (kudosGivers) getGiverSheet();
  }, [kudosGivers]);

  useEffect(() => {
    if (kudosSheets.length === NUM_SHEETS_TO_EXPORT) {
      const workBook = createWorkbook();
      addSheets(workBook, kudosSheets);
      saveWorkbook(workBook, filename);
      setKudosReceivers(undefined);
      setKudosGivers(undefined);
      setKudosSheets([]);
    }
  }, [kudosSheets]);

  return (
    <Button variant="primary" onClick={exportFile}>
      {children}
    </Button>
  );
};

export default ExportSpreadsheetButton;
