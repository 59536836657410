import styled from 'styled-components';

type AvatarSizes = 'small' | 'medium' | 'large';
const size: Record<AvatarSizes, string> = {
  small: '40px',
  medium: '48px',
  large: '120px',
};

const AvatarOnTo = styled.div`
  height: ${({ sizes = 'small' }: { sizes: AvatarSizes }): string =>
    size[sizes]};
  width: ${({ sizes = 'small' }: { sizes: AvatarSizes }): string =>
    size[sizes]};
  overflow: hidden;
  border-radius: 50%;
  position: relative;
  background-color: #2c303a;
  border: 2px solid #fbfbfc;
`;

export default AvatarOnTo;
