import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router';
import { Avatar, Typography, Loader, Box, Button } from '@wizeline/patio-ui';
import {
  Container,
  HeaderContainer,
  UserInfoContainer,
  AvatarContainer,
  NameContainer,
  KudosContainer,
  LeaderboardItemAction,
} from './LeaderboardItem.styled';

interface LeaderboardItemProps {
  /** Gives the position where the item should be listed, also helps to know if its the first item in the list */
  order: number;
  /** Url for the users avatar */
  avatarSrc: string;
  /** Name  of the user */
  name: string;
  /** Number of items of the actual action */
  quantity: number;
  /** Name of the action */
  action: string;
  /** Indicator to know if the item is the first one of the list */
  isFirstItem?: boolean;
  /** Flag to check if the item is loading */
  loading: boolean;
  registerID: number;
}

const GhostIndex: FunctionComponent<{ loading: boolean; order: number }> = ({
  loading,
  order,
}) => {
  return loading ? (
    <Loader width={24} height={24} variant="rect" />
  ) : (
    <Typography variant="bodyRegular">{order.toString()}</Typography>
  );
};

const GhostAvatar: FunctionComponent<{ loading: boolean; src: string }> = ({
  loading,
  src,
}) => {
  return (
    <Box height={48}>
      {loading ? (
        <Loader variant="circle" width={48} height={48} />
      ) : (
        <Avatar src={src} sizes="medium" />
      )}
    </Box>
  );
};

const GhostName: FunctionComponent<{
  loading: boolean;
  name: string;
  registerID: number;
  src: string;
}> = ({ loading, name, registerID }) => {
  const formatName = (givenName: string) => {
    return givenName.split('.').join(' ');
  };

  const history = useHistory();

  const handleProfileRedirect = () => {
    history.push({
      pathname: `/profile/${registerID}`,
    });
  };

  return loading ? (
    <Loader height={24} variant="rect" />
  ) : (
    <Button variant="link" onClick={handleProfileRedirect}>
      {formatName(name)}
    </Button>
  );
};

const GhostKudos: FunctionComponent<{
  loading: boolean;
  quantity: number;
  action: string;
}> = ({ loading, quantity, action }) => {
  return (
    <Box height={24}>
      {loading ? (
        <Loader width={120} variant="rect" />
      ) : (
        <>
          {quantity}
          &nbsp;
          <LeaderboardItemAction data-testid="leaderboard-item-action">
            {action}
          </LeaderboardItemAction>
        </>
      )}
    </Box>
  );
};

const LeaderboardItem: FunctionComponent<LeaderboardItemProps> = ({
  loading,
  order = 0,
  avatarSrc,
  name,
  quantity,
  action,
  isFirstItem,
  registerID,
}) => {
  return (
    <Container isFirstItem={isFirstItem} data-testid="leaderboard-item-order">
      {order !== 0 && (
        <HeaderContainer>
          <GhostIndex loading={loading} order={order} />
        </HeaderContainer>
      )}
      <UserInfoContainer>
        <AvatarContainer>
          <GhostAvatar loading={loading} src={avatarSrc} />
        </AvatarContainer>
        <NameContainer>
          <GhostName
            loading={loading}
            name={name}
            registerID={registerID}
            src={avatarSrc}
          />
        </NameContainer>
        {order !== 0 && (
          <KudosContainer data-testid="leaderboard-item-quantity">
            <GhostKudos loading={loading} quantity={quantity} action={action} />
          </KudosContainer>
        )}
      </UserInfoContainer>
    </Container>
  );
};

export default LeaderboardItem;
