import * as React from 'react';
import { Box, Stack } from '@wizeline/patio-ui';
import ProfileKudoSkeleton from 'components/UI/ProfileKudoItem/ProfileKudoSkeleton';

const ProfileKudosListSkeleton: React.FunctionComponent = () => {
  return (
    <Box paddingTop="10px">
      <Stack align="center">
        <ProfileKudoSkeleton />
        <ProfileKudoSkeleton />
        <ProfileKudoSkeleton />
      </Stack>
    </Box>
  );
};

export default ProfileKudosListSkeleton;
