import styled from 'styled-components';
import { device } from '../../../constants/breakpoints';

export const StyledInputContainer = styled.div`
  position: relative;
  width: 100%;

  @media ${device.mobileL} {
    max-width: 512px;
    min-width: 300px;
  }
`;

export const StyledSearchIcon = styled.span`
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 8px;
`;

export const StyledCloseIcon = styled.span`
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
`;

export const StyledButtonWrapper = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
`;

export const StyledImageIcon = styled.img.attrs(() => ({
  width: 16,
  height: 16,
}))`
  display: inline-flex;
`;
