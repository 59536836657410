import { useState, FunctionComponent, useEffect, Children } from 'react';
import styled from 'styled-components';
import { Box } from '@wizeline/patio-ui';
import TabItem from './TabItem';

const TabWrapper = styled(Box)`
  display: flex;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray[50]};
  padding-top: 12px;
  margin-top: 20px;
`;

const computeDefaults = ({ defaultKey, children }: TabsProps) => {
  let key: string;
  let content;
  if (!children) {
    key = '';
    return [key, content];
  }
  if (!Array.isArray(children)) {
    key = children.props.tabKey;
    content = children.props.children;
    return [key, content];
  }
  if (Array.isArray(children)) {
    if (defaultKey == null) {
      key = children[0].props.tabKey;
      content = children[0].props.children;
      return [key, content];
    }
    const foundChild = children.find((c) => c.props.tabKey === defaultKey);
    if (foundChild) {
      return [defaultKey, foundChild.props.children];
    }
    return [defaultKey, null];
  }

  return [defaultKey, null];
};

interface TabsProps {
  /** Sets the key of the tabitem that should be loaded when the component loads */
  defaultKey?: string;
  children?: React.ReactElement | React.ReactElement[];
  onActiveChange?: (tabKey: string) => void;
}

const Tabs: FunctionComponent<TabsProps> = ({
  defaultKey: initialDefaultKey,
  children: initialChildren,
  onActiveChange,
}: TabsProps) => {
  const [defaultKey, defaultContent] = computeDefaults({
    defaultKey: initialDefaultKey,
    children: initialChildren,
  });

  const [activeChildren, setActiveChildren] = useState(defaultKey);
  const [content, setContent] = useState(defaultContent);

  useEffect(() => {
    const [computedKey, computedContent] = computeDefaults({
      defaultKey: activeChildren,
      children: initialChildren,
    });
    setActiveChildren(computedKey);
    setContent(computedContent);
  }, [initialChildren, activeChildren]);

  return (
    <div data-testid="tabs-test">
      <TabWrapper>
        {Children.map(initialChildren, (child) => (
          <TabItem
            key={child?.props.tabKey}
            text={child?.props.text}
            tabKey={child?.props.tabKey}
            active={child?.props.tabKey === activeChildren}
            onClick={() => {
              const { tabKey, children } = child?.props;
              setActiveChildren(tabKey);

              if (onActiveChange) {
                onActiveChange(tabKey);
              }

              setContent(children);
            }}
          />
        ))}
      </TabWrapper>
      <div>{content}</div>
    </div>
  );
};

export default Tabs;
