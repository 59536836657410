import { Typography } from '@wizeline/patio-ui';
import styled from 'styled-components';
import { device } from 'constants/breakpoints';

export const DashboardHeader = styled(Typography)`
  font-family: Inter;
  font-size: 32px;
  font-weight: 600;
  margin: 0;
  padding: 0;
  text-align: center;

  @media ${device.tablet} {
    font-size: 36px;
  }
`;

export const KudoCounterHeader = styled(Typography)`
  font-family: Source Sans Pro;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  padding: 0;
  text-align: center;

  @media ${device.tablet} {
    font-size: 18px;
  }
`;

export const KudoCounterNumber = styled(Typography)`
  font-family: Source Sans Pro;
  font-size: 20px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-align: center;
  color: #626f7c;

  @media ${device.tablet} {
    font-size: 26px;
  }
`;
