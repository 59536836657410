import styled from 'styled-components';

export const ButtonLink = styled.button<{
  isActive?: boolean;
  isDisabled?: boolean;
}>`
  height: 40px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 4px;
  border: none;
  background: transparent;
  cursor: pointer;

  color: ${(props) => {
    if (props.isDisabled) {
      return props.theme.colors.gray[50];
    }

    if (props.isActive) {
      return props.theme.colors.white;
    }

    return props.theme.colors.primary.base;
  }};

  background: ${(props) =>
    props.isActive ? props.theme.colors.primary.base : 'transparent'};

  &:focus {
    outline: none;
  }
`;
