import { FunctionComponent, useState, useEffect, useRef } from 'react';
import useDebounce from 'hooks/useDebounce';
import CLOSE_ICON from 'styles/imgs/close-icon.svg';
import SEARCH_ICON from 'styles/imgs/search-icon.svg';
import TextField from '../TextField';
import {
  StyledCloseIcon,
  StyledInputContainer,
  StyledSearchIcon,
  StyledButtonWrapper,
  StyledImageIcon,
} from './SearchInput.styles';
import { SearchInputProps } from './SearchInput.types';

const SearchInput: FunctionComponent<SearchInputProps> = ({
  placeholder = 'Search',
  submitHandler,
  debounce = 1000,
  resetSearch = false,
  defaultValue = '',
}) => {
  const [searchTerm, setSearchTerm] = useState(defaultValue);
  const ignoreSubmitRef = useRef(true);
  const immediateRef = useRef(false);
  const debouncedSearchTerm = useDebounce(searchTerm, debounce);

  const clearHandler = () => {
    immediateRef.current = true;
    setSearchTerm('');
  };

  useEffect(() => {
    if (resetSearch && debouncedSearchTerm !== '') {
      ignoreSubmitRef.current = true;
      setSearchTerm('');
    }
  }, [resetSearch]);

  useEffect(() => {
    if (ignoreSubmitRef.current) {
      ignoreSubmitRef.current = false;
    } else if (immediateRef.current) {
      immediateRef.current = false;
      submitHandler(debouncedSearchTerm);
    } else {
      submitHandler(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);

  return (
    <StyledInputContainer>
      <StyledSearchIcon>
        <StyledImageIcon src={SEARCH_ICON} alt="Search icon" />
      </StyledSearchIcon>
      <TextField
        placeholder={placeholder}
        value={searchTerm}
        changeHandler={(event) => setSearchTerm(event.target.value)}
      />
      {searchTerm !== '' && (
        <StyledCloseIcon>
          <StyledButtonWrapper type="button" onClick={clearHandler}>
            <StyledImageIcon
              width={16}
              height={16}
              src={CLOSE_ICON}
              alt="Close Icon"
            />
          </StyledButtonWrapper>
        </StyledCloseIcon>
      )}
    </StyledInputContainer>
  );
};

export default SearchInput;
