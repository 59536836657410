import React, { memo, useEffect, useState } from 'react';
import { Box } from '@wizeline/patio-ui';
import { useLoadKudosData } from 'lib/kudos';
import { Tabs, TabItem, DateSelector } from 'components/UI';
import Leaderboard from 'components/Leaderboard';
import { LeaderboardHeader } from '../../components/Leaderboard/Leaderboard.styles';

interface LeaderboardItemData {
  name: string;
  image: string;
  kudosCount: number;
}

type ReceivedLeaderboardData = LeaderboardItemData;

interface HomeProps {
  receiversLeaderboard: ReceivedLeaderboardData[];
  totalReceivers: number;
  totalGivers: number;
  date: Date;
}

// TODO: check memoized function
const MemoizedLeaderboard = memo(Leaderboard);
const { REACT_APP_BASE_URL } = process.env;
const RECEIVERS_LIMIT = 20;
const GIVERS_LIMIT = 20;

const getURL = (
  pageNumber: number,
  limit: number,
  searchText = '',
  searchRegion = '',
  date = new Date(),
  endPoint = 'receivers',
  value?: string,
) => {
  const month = date.toLocaleDateString('en-US', { month: 'short' });
  const year = date.getFullYear();
  const url = `${REACT_APP_BASE_URL}/kudos/${endPoint}?page=${pageNumber}&limit=${limit}&searchTerm=${searchText}&timezones=${searchRegion}&year=${year}&month=${month}${
    value ? `&companyValue=${value}` : ''
  }`;
  return url;
};

const ValuesPage: React.FunctionComponent<HomeProps> = () => {
  const [isFirstRun, setIsFirstRun] = useState(true);
  const [currentTab, SetCurrentTab] = useState('community');
  const [initialDate] = useState(new Date());
  const [willLoadAllData, setWillLoadAllData] = useState(true);

  const community = useLoadKudosData({
    getURL,
    date: initialDate,
    endPoint: 'receivers',
    value: 'community',
  });

  const innovation = useLoadKudosData({
    getURL,
    date: initialDate,
    endPoint: 'receivers',
    value: 'innovation',
  });

  const ownership = useLoadKudosData({
    getURL,
    date: initialDate,
    endPoint: 'receivers',
    value: 'ownership',
  });

  const loadAllData = async (monthDate: Date) => {
    if (currentTab === 'community') {
      community.loadMonthData(monthDate).then(() => setIsFirstRun(false));
      innovation.loadMonthData(monthDate, true);
      ownership.loadMonthData(monthDate, true);
    } else if (currentTab === 'innovation') {
      innovation.loadMonthData(monthDate).then(() => setIsFirstRun(false));
      community.loadMonthData(monthDate, true);
      ownership.loadMonthData(monthDate, true);
    } else {
      ownership.loadMonthData(monthDate).then(() => setIsFirstRun(false));
      community.loadMonthData(monthDate, true);
      innovation.loadMonthData(monthDate, true);
    }
  };

  useEffect(() => {
    if (willLoadAllData) {
      loadAllData(initialDate);
    }

    return () => {
      setWillLoadAllData(false);
    };
  }, []);

  return (
    <>
      <Box
        display="flex"
        flexDirection={['column', 'row']}
        justifyContent={['center', 'space-between']}
        alignItems={['center', 'flex-end']}>
        <LeaderboardHeader>Values Leaderboard</LeaderboardHeader>
        <DateSelector initialDate={initialDate} onChange={loadAllData} />
      </Box>
      <Tabs
        defaultKey="community"
        onActiveChange={(tabKey) => {
          SetCurrentTab(tabKey);
        }}>
        <TabItem tabKey="community" text="Community">
          <MemoizedLeaderboard
            key="communityTab"
            leaderboardItems={community.leaderboardItems.receivers}
            leaderboardPage={community.page}
            totalItems={community.leaderboardItems.total}
            itemsPerPage={RECEIVERS_LIMIT}
            onLoadMoreData={community.onLoadMoreItems}
            actionText="Kudos received"
            loading={community.isLoading}
            firstRun={isFirstRun}
            resetSearch={community.resetSearch}
            error={community.error}
          />
        </TabItem>
        <TabItem tabKey="innovation" text="Innovation">
          <MemoizedLeaderboard
            key="innovationTab"
            leaderboardItems={innovation.leaderboardItems.receivers}
            leaderboardPage={innovation.page}
            totalItems={innovation.leaderboardItems.total}
            itemsPerPage={RECEIVERS_LIMIT}
            onLoadMoreData={innovation.onLoadMoreItems}
            actionText="Kudos received"
            loading={innovation.isLoading}
            firstRun={isFirstRun}
            resetSearch={innovation.resetSearch}
            error={innovation.error}
          />
        </TabItem>
        <TabItem tabKey="ownership" text="Ownership">
          <MemoizedLeaderboard
            key="ownershipTap"
            leaderboardItems={ownership.leaderboardItems.receivers}
            leaderboardPage={ownership.page}
            totalItems={ownership.leaderboardItems.total}
            itemsPerPage={GIVERS_LIMIT}
            onLoadMoreData={ownership.onLoadMoreItems}
            actionText="Kudos received"
            loading={ownership.isLoading}
            firstRun={isFirstRun}
            resetSearch={ownership.resetSearch}
            error={ownership.error}
          />
        </TabItem>
      </Tabs>
    </>
  );
};

export default ValuesPage;
