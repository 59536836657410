import React from 'react';
import { Loader, Box, Stack } from '@wizeline/patio-ui';

const KudosCountersSkeleton: React.FunctionComponent = () => {
  return (
    <Box>
      <Stack align="left">
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box marginBottom="20px">
            <Loader variant="rect" width={300} height={50} />
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          marginBottom="30px">
          <Box marginRight="20px">
            <Loader variant="rect" width={250} height={100} />
          </Box>
          <Box marginRight="20px">
            <Loader variant="rect" width={250} height={100} />
          </Box>
          <Box marginRight="20px">
            <Loader variant="rect" width={250} height={100} />
          </Box>
          <Box>
            <Loader variant="rect" width={250} height={100} />
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

export default KudosCountersSkeleton;
