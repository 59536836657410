import React from 'react';
import { AvatarGroupProps } from './AvatarGroup.types';
import StyleAvatarGroup from './AvatarGroup.styles';
import AvatarCounter from '../AvatarCounter';

const AvatarGroup: React.FC<AvatarGroupProps> = ({ max, total, children }) => {
  const avatars = React.Children.toArray(children);
  const visibleAvatars = avatars.slice(0, max);
  const value = total - max;

  return (
    <StyleAvatarGroup>
      {visibleAvatars}
      {value > 0 && <AvatarCounter sizes="small" src={value} />}
    </StyleAvatarGroup>
  );
};

export default AvatarGroup;
