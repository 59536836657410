export const toHumanDate = (date: Date): string => {
  const formatter = new Intl.DateTimeFormat('en');
  const formattedDate = formatter.format(date);
  return formattedDate;
};

const isSameDay = (date1: Date, date2: Date): boolean => {
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  );
};

export const toHumanDateAddToday = (date: Date): string => {
  const today = new Date();

  if (isSameDay(date, today)) {
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    const formattedTime = `${hours % 12 || 12}:${minutes
      .toString()
      .padStart(2, '0')} ${ampm}`;
    return `today at ${formattedTime}`;
  }
  const formatter = new Intl.DateTimeFormat('en');
  const formattedDate = formatter.format(date);
  return formattedDate;
};
