import styled from 'styled-components';

export const TabItemWrapper = styled.div<{ isActive?: boolean }>`
  margin-right: 8px;
  padding: 16px 12px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 16px;

  color: ${(props) =>
    props.isActive
      ? props.theme.colors.primary.base
      : props.theme.colors.gray[70]};
  cursor: ${(props) => (props.isActive ? 'default' : 'pointer')};
  border-bottom: ${(props) =>
    props.isActive ? `2px solid ${props.theme.colors.primary.base}` : 'none'};
`;
