import React from 'react';
// TODO: Remove react-spring dependency
import { useTransition, animated } from 'react-spring';
import { Container, ContentContainer, Content } from './Modal.styled';

export const ModalContent: React.FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }: { children: React.ReactNode }) => {
  return (
    <ContentContainer>
      <Content>{children}</Content>
    </ContentContainer>
  );
};

export const Modal: React.FunctionComponent<{
  isOpen: boolean;
  children: React.ReactNode;
}> = ({ isOpen, children }: { isOpen: boolean; children: React.ReactNode }) => {
  // TODO: Remove this dependency
  const transition = useTransition(isOpen, null, {
    from: { opacity: '0' },
    enter: { opacity: '1' },
    leave: { opacity: '0' },
  });

  return (
    <Container show={isOpen}>
      {transition.map(
        ({ item, key, props }) =>
          item && (
            <animated.div key={key} style={{ ...props }}>
              {children}
            </animated.div>
          ),
      )}
    </Container>
  );
};
