import * as React from 'react';
import { Avatar, Box, Typography } from '@wizeline/patio-ui';
import { User } from '@auth0/auth0-spa-js';
import {
  KudoStat,
  KudosStatsContainer,
  KudosStatDescriptor,
  KudoDivider,
} from 'components/UI/ProfileKudosStats';

interface ProfileProps {
  user: User;
  totalRecievers: number;
  totalGivers: number;
}

const Profile: React.FunctionComponent<ProfileProps> = ({
  user,
  totalRecievers,
  totalGivers,
}) => {
  function formatName(name: string) {
    const result = name.split('.');
    for (let i = 0; i < result.length; i++) {
      result[i] = result[i].charAt(0).toUpperCase() + result[i].slice(1);
    }
    return result.join(' ');
  }

  const avatarPicture = user.picture;
  const profileAvatarName = formatName(user.giverName);
  return (
    <Box padding="0">
      <Box
        display="flex"
        justifyContent="left"
        alignItems="center"
        marginBottom="8px">
        <Box marginRight="16px">
          {avatarPicture ? (
            <Avatar sizes="large" src={avatarPicture} />
          ) : (
            <Avatar sizes="large" />
          )}
        </Box>
        <Box marginBottom="8px" marginLeft="36px">
          {profileAvatarName ? (
            <Typography variant="profileSemiBold">
              {profileAvatarName}
            </Typography>
          ) : (
            ''
          )}
          <KudosStatsContainer>
            <KudoStat>{`${totalRecievers}`}</KudoStat>
            <KudosStatDescriptor>Kudos Received</KudosStatDescriptor>
            <KudoDivider> </KudoDivider>
            <KudoStat>{`${totalGivers}`}</KudoStat>
            <KudosStatDescriptor>Kudos Given</KudosStatDescriptor>
          </KudosStatsContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default Profile;
