/* eslint-disable prettier/prettier */
import { useEffect } from 'react';
import useFetch from '../hooks/useFetch';

const { REACT_APP_BASE_URL } = process.env;

export interface Counters {
  today: number;
  lastMonth: number;
  thisMonth: number;
  all: number;
}

export interface totalKudos {
  loading: boolean;
  totalCounters: Counters;
}

export function useLoadTotalKudos(): totalKudos {
  const {
    dataAPI: countersKudos,
    isLoading: isLoadingCountersKudos,
    fetchApi: fetchCountersKudos,
  } = useFetch();

  const kudosURL = `${REACT_APP_BASE_URL}/kudos/totalkudos`;
  const { totalCounters } = countersKudos;
  useEffect(() => {
    const getCountersKudos = async () => {
      await fetchCountersKudos(kudosURL);
    };
    getCountersKudos();
  }, []);

  return {
    loading: isLoadingCountersKudos,
    totalCounters,
  };
}
