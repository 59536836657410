import { useState } from 'react';
import { Typography, Box, Button } from '@wizeline/patio-ui';
import { months, years } from 'constants/months-years';
import ExportSpreadsheetButton from 'components/ExportSpreadsheetButton';
import { Dropdown } from 'components/UI';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'components/UI/Modal';
import { Label } from './ExportModal.styles';

interface ExportModalProps {
  isOpen: boolean;
  onCloseModal: () => void;
}

const defaultYear = String(new Date().getFullYear());

const ExportModal: React.FunctionComponent<ExportModalProps> = ({
  isOpen,
  onCloseModal,
}) => {
  const [monthForExport, setMonthForExport] = useState('Jan');
  const [yearForExport, setYearForExport] = useState(defaultYear);

  return (
    <>
      <Modal isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Export Data</ModalHeader>
          <ModalBody>
            <Box paddingBottom={16}>
              <Typography variant="bodyRegular" color="gray.70">
                Please select month & year
              </Typography>
            </Box>
            <Box display="flex" paddingBottom={16}>
              <Box width={130} marginRight={35}>
                <Label>Month</Label>
                <Dropdown
                  title="Months"
                  options={months}
                  defaultValue={monthForExport}
                  onChange={(option) => setMonthForExport(option.value)}
                />
              </Box>
              <Box width={114}>
                <Label>Year</Label>
                <Dropdown
                  title="Years"
                  options={years}
                  defaultValue={yearForExport}
                  onChange={(option) => setYearForExport(option.value)}
                />
              </Box>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Box marginRight={16}>
              <Button variant="secondary" onClick={onCloseModal}>
                Cancel
              </Button>
            </Box>
            <Box>
              <ExportSpreadsheetButton
                filename={`Kudos${monthForExport}${yearForExport}.xlsx`}
                month={monthForExport}
                year={yearForExport}>
                Export
              </ExportSpreadsheetButton>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ExportModal;
