import React, { useState } from 'react';
import { Loader, AvatarDefault } from '@wizeline/patio-ui';
import { AvatarCounterProps, AvatarCounterSizes } from './AvatarCounter.types';
import StyleAvatarCounter from './AvatarCounter.styles';
import AvatarOnTo from '../AvatarOnTo.Styles';

const size: { [key in AvatarCounterSizes]: number } = {
  small: 40,
  medium: 48,
  large: 120,
};

const AvatarCounter: React.FC<AvatarCounterProps> = (props) => {
  const { sizes = 'large', src } = props;
  const avatarSize = size[sizes];
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  return (
    <>
      {!undefined && !error ? (
        <AvatarOnTo sizes={sizes}>
          <StyleAvatarCounter
            sizes={sizes}
            onLoad={() => setLoading(true)}
            onError={() => setError(true)}>
            {loading ? (
              <div data-testid="avatar-loader">
                <Loader
                  variant="circle"
                  width={avatarSize}
                  height={avatarSize}
                />
              </div>
            ) : null}
            {`+${src}`}
          </StyleAvatarCounter>
        </AvatarOnTo>
      ) : (
        <AvatarDefault width={`${avatarSize}`} height={`${avatarSize}`} />
      )}
    </>
  );
};

export default AvatarCounter;
