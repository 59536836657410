import * as React from 'react';
import { useHistory } from 'react-router';
import parse from 'html-react-parser';
import { Avatar, Box, Card, Button, ValueTag } from '@wizeline/patio-ui';
import { toHumanDate } from 'utils/dates';
import {
  KudoGiverHeader,
  KudoGivenDate,
  KudoGiverHeaderWrapper,
} from './ProfileKudoItem.styled';
import { Kudo } from '../../../types/ProfileData.types';
import { KudoMessage } from '../ProfileKudosStats';

interface ProfileKudoItemProps {
  kudo: Kudo;
}

interface KudoLinkProps {
  kudoID: string;
}

const ProfileKudoItem: React.FunctionComponent<ProfileKudoItemProps> = ({
  kudo,
}) => {
  let component = null;
  if (kudo.giver_value_tag)
    component = <ValueTag value={kudo.giver_value_tag.toLowerCase()} />;
  const history = useHistory();

  function handleProfileRedirect({ kudoID }: KudoLinkProps) {
    history.push({
      pathname: `/profile/${kudoID}`,
    });
  }
  function renderFormattedMessage() {
    let { message } = kudo;
    const pattern = /@(?=.{3,20}(?:\s|$))[a-z][a-z0-9]+(?:[._][a-z0-9]+)?/gi;
    const usernames = message.match(pattern);
    usernames?.forEach((user) => {
      message = message.replace(user, `<span>${user}</span>`);
    });
    return message;
  }

  return (
    <Card
      boxShadow="base"
      width="100%"
      display="flex"
      flexDirection="column"
      padding="24px"
      marginY="12px">
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center">
        <Box display="flex" alignItems="center">
          <Avatar sizes="small" src={kudo.giver_image} />
          <KudoGiverHeaderWrapper>
            <Button
              variant="link"
              onClick={() => handleProfileRedirect({ kudoID: kudo.giver_id })}>
              <KudoGiverHeader>
                {kudo.giver_name.replace('.', ' ')}
              </KudoGiverHeader>
            </Button>
          </KudoGiverHeaderWrapper>
        </Box>
        <Box display="flex" alignItems="center">
          <KudoGivenDate>
            {toHumanDate(new Date(kudo.created_at))}
          </KudoGivenDate>
        </Box>
      </Box>
      <Box marginY="8px">
        <KudoMessage>{parse(renderFormattedMessage())}</KudoMessage>
      </Box>
      <Box>{component}</Box>
    </Card>
  );
};

export default ProfileKudoItem;
