import styled from 'styled-components';
import BoxImageOnTo from '../AvatarOnTo.Styles';

const StyleAvatarGroup = styled.div`
display: flex;
margin-left: 20px;
position: relative;
&.rtl {
  direction: rtl;
  
  avatar:hover:not(:last-of-type) {
    transform: translate(10px);
  }
}

${BoxImageOnTo} {
  margin-left: -16px;
  transition: transform 0.3s ease;
  cursor: pointer;
  
  &:hover:not(:last-of-type) {
     transform: translate(-10px);
  }
`;

export default StyleAvatarGroup;
