import React, { FunctionComponent } from 'react';
import styled, { keyframes } from 'styled-components';
import Circle from './Circle';
import Tomato from './Tomato';
import Salmon from './Salmon';
import { device } from '../../../constants/breakpoints';

const StyledContainer = styled.div`
  position: relative;
  width: 200px;
  height: 300px;
`;

const MainContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  padding-top: 64px;

  @media ${device.mobileL} {
    padding: 156px;
  }

  @media ${device.tablet} {
    padding: 200px;
  }
`;

const tomatoMovement = keyframes`
  0% {
    top: calc(50%);
    left: 50%;
    transform: translate(-50%, -50%);
  }
  10% {
    top: 20%;
    left: 10%;
  }
  20% {
    top: 50%;
    left: 10%;
  }
  30% {
    top: 50%;
    left: 10%;
  }
  40% {
    top: 80%;
    left: 10%;
  }
  50% {
    top: calc(50%);
    left: 50%;
    transform: translate(-50%, -50%);
  }
  100% {
    top: calc(50%);
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const salmonMovement = keyframes`
  0% {
    top: calc(50% + 43px);
    left: 50%;
    transform: translate(-50%, -50%);
  }
  10% {
    top: 80%;
    left: 90%;
  }
  20% {
    top: 80%;
    left: 90%;
  }
  30% {
    top: 20%;
    left: 90%;
  }
  40% {
    top: 20%;
    left: 90%;
  }
  50% {
    top: calc(50% + 43px);
    left: 50%;
    transform: translate(-50%, -50%);
  }
  100% {
    top: calc(50% + 43px);
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const circleMovement = keyframes`
  0% {
    top: calc(50% + 18px);
    left: calc(50% + 18px);
    transform: translate(-50%, -50%);
  }
  10% {
    top: 80%;
    left: calc(90% - 50px);
  }
  20% {
    top: 50%;
    left: calc(10% + 50px);
  }
  30% {
    top: 20%;
    left: calc(90% - 50px);
  }
  40% {
    top: 80%;
    left: calc(10% + 50px);
  }
  50% {
    top: calc(50% + 18px);
    left: calc(50% + 18px);
    transform: translate(-50%, -50%);
  }
  100% {
    top: calc(50% + 18px);
    left: calc(50% + 18px);
    transform: translate(-50%, -50%);
  }
`;

/*
  we can add: animation-delay: 0.8s; if we want a delay,
  I took it out because the page wasnt showing the animation
  unless the connection is very slow.
*/

const TomatoRectangle = styled(Tomato)`
  position: absolute;
  top: calc(50%);
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ${tomatoMovement} 4s ease-in-out;
  animation-iteration-count: infinite;
`;

const SalmonRectangle = styled(Salmon)`
  position: absolute;
  top: calc(50% + 43px);
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ${salmonMovement} 4s ease-in-out;
  animation-iteration-count: infinite;
`;

const CircleElem = styled(Circle)`
  position: absolute;
  top: calc(50% + 18px);
  left: calc(50% + 18px);
  transform: translate(-50%, -50%);
  animation: ${circleMovement} 4s ease-in-out;
  animation-iteration-count: infinite;
`;

const LoadingPage: FunctionComponent = () => {
  return (
    <MainContainer>
      <StyledContainer>
        <SalmonRectangle />
        <TomatoRectangle />
        <CircleElem />
      </StyledContainer>
    </MainContainer>
  );
};

export default LoadingPage;
