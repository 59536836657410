/* eslint-disable prettier/prettier */
import React from 'react';
import { Box } from '@wizeline/patio-ui';
import { DashboardHeader } from 'components/UI/KudosCounters/KudosCounters.styles';
import { useLoadTotalKudos } from 'lib/totalkudos';
import KudosCountersSkeleton from 'components/UI/KudosCounters/KudosCountersSkeleton';
import KudoCounter from 'components/UI/KudosCounters';

const Dashboard: React.FunctionComponent = () => {
  const { totalCounters, loading } = useLoadTotalKudos();
  if (loading) {
    return <KudosCountersSkeleton />;
  }
  
  return (
        <>
          <Box display="flex" justifyContent="left" alignItems="left">
            <DashboardHeader>Dashboard</DashboardHeader>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            marginY="25px">
            <KudoCounter name="Total Kudos" number={totalCounters.all} />
            <KudoCounter name="Last Month" number={totalCounters.lastMonth} />
            <KudoCounter name="This Month" number={totalCounters.thisMonth} />
            <KudoCounter name="Today" number={totalCounters.today} />
          </Box>
        </>
  );
};
export default Dashboard;
