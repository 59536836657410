import styled from 'styled-components';
import { Typography, Box } from '@wizeline/patio-ui';

export const KudoStat = styled(Typography)`
  font-size: 14px;
  font-weight: bold;
`;

export const KudosStatDescriptor = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  margin-left: 0.5em;
`;

export const KudoDivider = styled(Typography)`
  border-left: 1.5px solid rgba(92, 87, 87, 0.32);
  height: 20px;
  margin-left: 0.5em;
  margin-right: 0.5em;
`;

export const KudosStatsContainer = styled(Box)`
  color: #626f7c;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`;

export const KudoMessage = styled(Typography)`
  word-break: break-all;
  overflow-wrap: break-word;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  & > span {
    color: #008098;
  }
`;

export const SpanMessage = styled.span`
  color: #008098;
  padding: 0;
  border: none;
  background-color: transparent;
  font-size: 16px;
  line-height: 22px;

  &:hover {
    text-decoration-line: underline;
    text-decoration-color: #008098;
    text-decoration-thickness: 1px;
    cursor: pointer;
  }
`;

export const SpanEmojiImage = styled.span`
  width: 22px;
  height: 22px;
  margin-top: -11px;
  object-fit: contain;
  top: 50%;
  overflow: hidden;
`;
