import React from 'react';

const Salmon: React.FunctionComponent = (props: any) => {
  return (
    <svg
      width="35px"
      height="53px"
      viewBox="0 0 35 53"
      version="1.1"
      {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-693.000000, -308.000000)" fill="#FF8D6B">
          <path d="M724.192477,308 L720.399699,308 L696.807523,308 C694.704714,308 693,309.735806 693,311.876968 L693,353.261078 L693,357.123032 C693,359.264909 694.704714,361 696.807523,361 L700.600301,361 L724.192477,361 C726.295286,361 728,359.264909 728,357.123032 L728,315.738922 L728,311.876968 C728,309.735806 726.295286,308 724.192477,308" />
        </g>
      </g>
    </svg>
  );
};
export default Salmon;
