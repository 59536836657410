import { useReducer } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { SET_DATA, DATA_LOADING, DATA_ERROR } from 'constants/kudosActions';

const initialData = {
  dataAPI: [],
  isLoading: true,
  error: null,
};

interface DataReducer {
  dataAPI: any;
  isLoading: boolean;
  error: any;
}

const dataReducer = (state: DataReducer, action: any) => {
  switch (action.type) {
    case DATA_LOADING:
      return { ...state, isLoading: true, error: null };
    case SET_DATA:
      return {
        ...state,
        dataAPI: action.payload.data,
        isLoading: false,
        error: null,
      };
    case DATA_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.err,
      };
    default:
      throw new Error();
  }
};

const useFetch = (): any => {
  const { getAccessTokenSilently } = useAuth0();
  const [state, dispatch] = useReducer(dataReducer, initialData);
  const fetchApi = async (url: RequestInfo) => {
    try {
      const token = await getAccessTokenSilently();
      const init = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      dispatch({ type: DATA_LOADING });
      const response: any = await fetch(url, init);
      const { data } = (await response.json()) || {};
      dispatch({ type: SET_DATA, payload: { data } });
    } catch (err: any) {
      dispatch({ type: DATA_ERROR, payload: { err } });
    }
  };

  return { ...state, fetchApi };
};

export default useFetch;
