import React, { memo, useEffect, useState } from 'react';
import { Box } from '@wizeline/patio-ui';
import { useLoadKudosData } from 'lib/kudos';
import { Tabs, TabItem, DateSelector } from 'components/UI';
import Leaderboard from 'components/Leaderboard';
import { LeaderboardHeader } from '../../components/Leaderboard/Leaderboard.styles';

interface LeaderboardItemData {
  name: string;
  image: string;
  kudosCount: number;
}

type ReceivedLeaderboardData = LeaderboardItemData;

interface HomeProps {
  receiversLeaderboard: ReceivedLeaderboardData[];
  totalReceivers: number;
  totalGivers: number;
  date: Date;
}

const MemoizedLeaderboard = memo(Leaderboard);
const { REACT_APP_BASE_URL } = process.env;
const RECEIVERS_LIMIT = 20;
const GIVERS_LIMIT = 20;

const getURL = (
  pageNumber: number,
  limit: number,
  searchText = '',
  searchRegion = '',
  date = new Date(),
  endPoint = 'receivers',
) => {
  const month = date.toLocaleDateString('en-US', { month: 'short' });
  const year = date.getFullYear();
  const url = `${REACT_APP_BASE_URL}/kudos/${endPoint}?page=${pageNumber}&limit=${limit}&searchTerm=${searchText}&timezones=${searchRegion}&year=${year}&month=${month}`;
  return url;
};

const Homepage: React.FunctionComponent<HomeProps> = () => {
  const [isFirstRun, setIsFirstRun] = useState(true);
  const [currentTab, SetCurrentTab] = useState('receivers');
  const [initialDate] = useState(new Date());
  const [willLoadAllData, setWillLoadAllData] = useState(true);

  const receivers = useLoadKudosData({
    getURL,
    date: initialDate,
    endPoint: 'receivers',
  });

  const givers = useLoadKudosData({
    getURL,
    date: initialDate,
    endPoint: 'givers',
  });

  const loadAllData = async (monthDate: Date) => {
    if (currentTab === 'receivers') {
      receivers.loadMonthData(monthDate).then(() => setIsFirstRun(false));
      givers.loadMonthData(monthDate, true);
    } else {
      givers.loadMonthData(monthDate).then(() => setIsFirstRun(false));
      receivers.loadMonthData(monthDate, true);
    }
  };

  useEffect(() => {
    if (willLoadAllData) {
      loadAllData(initialDate);
    }

    return () => {
      setWillLoadAllData(false);
    };
  }, []);

  return (
    <>
      <Box
        display="flex"
        flexDirection={['column', 'row']}
        justifyContent={['center', 'space-between']}
        alignItems={['center', 'flex-end']}>
        <LeaderboardHeader>Kudos Leaderboard</LeaderboardHeader>
        <DateSelector initialDate={initialDate} onChange={loadAllData} />
      </Box>
      <Tabs
        defaultKey="receivers"
        onActiveChange={(tabKey) => {
          SetCurrentTab(tabKey);
        }}>
        <TabItem tabKey="receivers" text="Receivers">
          <MemoizedLeaderboard
            key="receiversTab"
            leaderboardItems={receivers.leaderboardItems.receivers}
            leaderboardPage={receivers.page}
            totalItems={receivers.leaderboardItems.total}
            itemsPerPage={RECEIVERS_LIMIT}
            onLoadMoreData={receivers.onLoadMoreItems}
            actionText="Kudos received"
            loading={receivers.isLoading}
            firstRun={isFirstRun}
            resetSearch={receivers.resetSearch}
            error={receivers.error}
          />
        </TabItem>
        <TabItem tabKey="givers" text="Givers">
          <MemoizedLeaderboard
            key="giversTab"
            leaderboardItems={givers.leaderboardItems.givers}
            leaderboardPage={givers.page}
            totalItems={givers.leaderboardItems.total}
            itemsPerPage={GIVERS_LIMIT}
            onLoadMoreData={givers.onLoadMoreItems}
            actionText="Kudos sent"
            loading={givers.isLoading}
            firstRun={isFirstRun}
            resetSearch={givers.resetSearch}
            error={givers.error}
          />
        </TabItem>
      </Tabs>
    </>
  );
};

export default Homepage;
