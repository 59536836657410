import styled from 'styled-components';

export const DropdownLink = styled.a`
  padding: 8px 16px;
  display: block;

  &:hover {
    color: ${(props) => props.theme.colors.primary.hover};
    background: ${(props) => props.theme.colors.gray[20]};
    cursor: pointer;
  }
`;

interface DropdownListContainerProps {
  isOpen?: boolean;
}

export const DropdownListContainer = styled.div<DropdownListContainerProps>`
  position: absolute;
  right: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 192px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 10%),
    0 10px 10px -5px rgb(0 0 0 / 4%);
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
`;

export const DropdownContainer = styled.div`
  position: relative;
`;

export const DropdownButton = styled.button`
  display: flex;
  align-items: center;
  text-decoration: none;
  padding-top: 16px;
  padding-bottom: 16px;
  border: none;
  background: transparent;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export const DropdownUsernameContainer = styled.span`
  color: ${(props) => props.theme.colors.gray[100]};
  margin-left: 8px;
  margin-right: 8px;
  font-family: Source Sans Pro;
  font-size: 16px;
  font-weight: 600;
`;

export const DropdownArrowImg = styled.img.attrs(() => ({
  width: '18px',
  height: '18px',
  alt: 'Dropdown Arrow Icon',
}))``;
