/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Card, Button } from '@wizeline/patio-ui';
import { toHumanDateAddToday } from 'utils/dates';
import { Message } from 'types/MessageData.types';
import Avatar from '../Avatar/Avatar';
import {
  KudoGiverHeader,
  KudoGivenDate,
  KudoGiverHeaderWrapper,
} from '../ProfileKudoItem/ProfileKudoItem.styled';

import { KudoMessage, SpanEmojiImage, SpanMessage } from '../ProfileKudosStats';

import AvatarGroup from '../Avatar/AvatarGroup';

interface ProfileKudoItemProps {
  kudo: Message;
}

interface KudoLinkProps {
  kudoID: string;
}

const TimelineKudoItem: React.FunctionComponent<ProfileKudoItemProps> = ({
  kudo,
}) => {
  const navigate = useHistory();
  const receiverProfileId = kudo.receiver_id;
  const receiverProfileImages = kudo.receiver_profile_image;
  const receiverProfileName = kudo.receiver_name;
  const numProfileImages = receiverProfileImages.length;
  const numProfileIds = receiverProfileId.length;

  function handleProfileRedirect({ kudoID }: KudoLinkProps) {
    navigate.push(`/profile/${kudoID}`);
  }

  function renderFormattedMessage() {
    const { raw_text } = kudo;
    const pattern = /@(?=.{3,20}(?:\s|$))[a-z][a-z0-9]+(?:[._][a-z0-9]+)?/gi;
    const emojiURLPattern =
      /https:\/\/emoji\.slack-edge\.com\/[A-Z0-9]+\/[^/]+\/[^.]+\.(gif|jpg|png)/g;
    const usernames = raw_text.match(pattern) || [];
    const emogis = raw_text.match(emojiURLPattern) || [];
    const messageComponents = [];
    let lastIndex = 0;

    function updateLastIndex(index: number, length: number) {
      return index + length;
    }

    usernames.forEach((user) => {
      const index = raw_text.indexOf(user, lastIndex);
      messageComponents.push(raw_text.substring(lastIndex, index));
      const userComponent = (
        <SpanMessage
          as="button"
          onClick={() =>
            handleProfileRedirect({ kudoID: receiverProfileId.toString() })
          }>
          {user}
        </SpanMessage>
      );
      messageComponents.push(userComponent);

      lastIndex = updateLastIndex(index, user.length);
    });

    emogis.forEach((emogi) => {
      const index = raw_text.indexOf(emogi, lastIndex);
      messageComponents.push(raw_text.substring(lastIndex, index));

      const emogiComponent = <SpanEmojiImage as="img" src={emogi} />;
      messageComponents.push(emogiComponent);

      lastIndex = updateLastIndex(index, emogi.length);
    });

    messageComponents.push(raw_text.substring(lastIndex));

    return <>{messageComponents}</>;
  }

  return (
    <Card
      boxShadow="base"
      width="100%"
      display="flex"
      flexDirection="column"
      padding="24px"
      marginY="12px">
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center">
        <Box display="flex" alignItems="center">
          <Avatar sizes="small" src={kudo.giver_profile_image} />
          <KudoGiverHeaderWrapper>
            <Button
              variant="link"
              onClick={() =>
                handleProfileRedirect({ kudoID: kudo.giver_id.toString() })
              }>
              <KudoGiverHeader>
                {kudo.giver_name.replace('.', ' ')}
              </KudoGiverHeader>
            </Button>
          </KudoGiverHeaderWrapper>
          {numProfileImages === 1 ? (
            <>
              <Box alignItems="center" display="flex">
                <Box alignItems="center" display="flex" marginRight={2}>
                  to
                </Box>
                <Avatar sizes="small" src={receiverProfileImages[0]} />
                <KudoGiverHeaderWrapper>
                  <Button
                    variant="link"
                    onClick={() =>
                      handleProfileRedirect({
                        kudoID: receiverProfileId[0].toString(),
                      })
                    }>
                    <KudoGiverHeader>
                      {receiverProfileName[0].replace('.', ' ')}
                    </KudoGiverHeader>
                  </Button>
                </KudoGiverHeaderWrapper>
              </Box>
            </>
          ) : (
            <>
              <Box alignItems="center" display="flex" marginRight={2}>
                to
              </Box>
              <AvatarGroup max={3} total={numProfileIds}>
                {receiverProfileImages.map((image) => (
                  <Avatar sizes="small" key={image} src={image} />
                ))}
              </AvatarGroup>
            </>
          )}
        </Box>
        <Box display="flex" alignItems="center">
          <KudoGivenDate>
            {toHumanDateAddToday(new Date(kudo.created_at))}
          </KudoGivenDate>
        </Box>
      </Box>
      <Box marginY="8px">
        <KudoMessage>{renderFormattedMessage()}</KudoMessage>
      </Box>
    </Card>
  );
};
export default TimelineKudoItem;
