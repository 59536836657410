import { FunctionComponent } from 'react';
import { TabItemWrapper } from './TabItem.styles';
import { TabItemProps } from './TabItem.types';

const TabItem: FunctionComponent<TabItemProps> = ({
  text,
  active,
  onClick,
}) => {
  return (
    <TabItemWrapper onClick={onClick} isActive={active}>
      {text}
    </TabItemWrapper>
  );
};

export default TabItem;
