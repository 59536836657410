import styled from 'styled-components';
import { AvatarCounterSizes } from './AvatarCounter.types';

export const size: { [key in AvatarCounterSizes]: string } = {
  small: '40px',
  medium: '48px',
  large: '120px',
};

export const StyleAvatarCounter = styled.div`
  object-fit: cover;
  border-radius: 50%;
  height: ${({ sizes = 'large' }: { sizes: AvatarCounterSizes }): string =>
    size[sizes]};
  width: ${({ sizes = 'large' }: { sizes: AvatarCounterSizes }): string =>
    size[sizes]};
  display: flex;
  justify-content: center;
  align-items: center;
  background: #323232;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  item-align: center;
  text-align: center;
`;

export default StyleAvatarCounter;
