import { useEffect, useState } from 'react';
import useFetch from '../hooks/useFetch';
import { useTotalMessagesDataType } from '../types/MessageData.types';

const { REACT_APP_BASE_URL } = process.env;

function getURL(page: number, itemsPerPage: number) {
  const kudosURL = `${REACT_APP_BASE_URL}/kudos/timeline?page=${page}&limit=${itemsPerPage}&range=&searchTerm=&orderOrientation=DESC`;
  return kudosURL;
}

export function useTotalKudosData(): useTotalMessagesDataType {
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;
  const [profileItems, setProfileItems] = useState<any[]>([]);
  const {
    dataAPI: kudosData,
    isLoading: isLoadingKudos,
    error: kudosError,
    fetchApi: fetchKudos,
  } = useFetch();

  useEffect(() => {
    const { kudos: newProfileItems = [] } = kudosData;
    setProfileItems((oldProfileItems) => [
      ...oldProfileItems,
      ...newProfileItems,
    ]);
  }, [kudosData]);

  useEffect(() => {
    fetchKudos(getURL(page, itemsPerPage));
  }, [page]);

  const loadMore = async () => {
    await setPage(page + 1);
  };

  return {
    profileItems,
    loadMore,
    loading: isLoadingKudos,
    error: kudosError,
  };
}
