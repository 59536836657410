import React, { useState } from 'react';
import PageLink from 'components/UI/Paginator/PageLink';
import {
  DateSelectorContainer,
  DateContainer,
  Bold,
} from './DateSelector.styles';

interface DateSelectorProps {
  initialDate: Date;
  onChange?: (newDate: Date) => Promise<void>;
  locale?: string;
}

const getMonthYear = (date: Date, locale: string) => {
  const month: string = date.toLocaleDateString(locale, { month: 'long' });
  const year: string = date.toLocaleDateString(locale, { year: 'numeric' });
  return [month, year];
};

const DateSelector: React.FunctionComponent<DateSelectorProps> = ({
  initialDate,
  onChange,
  locale = 'en-US',
}) => {
  const [date, setDate] = useState(new Date(initialDate));
  const [month, year] = getMonthYear(date, locale);
  const [actualMonth, actualYear] = getMonthYear(new Date(), locale);
  const areDatesEq = actualMonth === month && actualYear === year;

  const increaseMonth = async () => {
    setDate(new Date(date.setMonth(date.getMonth() + 1)));
    if (onChange !== undefined) onChange(date);
  };
  const decreaseMonth = async () => {
    setDate(new Date(date.setMonth(date.getMonth() - 1)));
    if (onChange !== undefined) onChange(date);
  };

  return (
    <DateSelectorContainer data-testid="date-selector-component">
      <PageLink
        onClick={decreaseMonth}
        data-testid="date-selector-decrease-month">
        <Bold>&lsaquo;</Bold>
      </PageLink>
      <DateContainer data-testid="date-selector-description">
        {`${month} ${year}`}
      </DateContainer>
      <PageLink
        onClick={increaseMonth}
        isDisabled={areDatesEq}
        data-testid="date-selector-increase-month">
        <Bold>&rsaquo;</Bold>
      </PageLink>
    </DateSelectorContainer>
  );
};

export default DateSelector;
