import styled from 'styled-components';
import { device } from 'constants/breakpoints';

export const Container = styled.div<{ isFirstItem?: boolean }>`
  display: flex;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-color: ${(props) => props.theme.colors.gray[40]};
  border-top-width: ${(props) => (!props.isFirstItem ? '1px' : 'none')};
`;

export const HeaderContainer = styled.div`
  min-width: 1.5rem;
`;

export const AvatarContainer = styled.div`
  margin-left: 0rem;
  margin-right: 0.5rem;
  @media ${device.mobileL} {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  @media ${device.tablet} {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
`;

export const NameContainer = styled.div`
  width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  white-space: nowrap;
  font-weight: 600;
  color: ${(props) => props.theme.colors.text.subtitle};

  & > button {
    text-align: left;
    font-family: Source Sans Pro;
    font-weight: 600;
    text-transform: capitalize;
    padding: 0;
  }
`;

export const KudosContainer = styled.div`
  flex-basis: 156px;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
  flex: 1 1 auto;
  color: ${(props) => props.theme.colors.gray[100]};
`;

export const UserInfoContainer = styled.div`
  display: flex;
  width: 100%;
  flex-grow: 1;
  align-items: center;
`;

export const UserTextInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LeaderboardItemAction = styled.span`
  color: ${(props) => props.theme.colors.gray[60]};
`;
