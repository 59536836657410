import React, { useEffect, useState } from 'react';
import { States, Box, Stack, DateRangePicker, Modal } from '@wizeline/patio-ui';
import { useAuth0 } from '@auth0/auth0-react';
import { useProfileData } from 'lib/useProfileData';
import { useParams } from 'react-router';
import {
  Dropdown,
  Paginator,
  PaginatorInfo,
  SearchInput,
  TabItem,
  Tabs,
} from 'components/UI';
import { format, isEqual } from 'date-fns';
import ProfileSkeletonHeader from 'components/Profile/ProfileSkeletonHeader';
import ProfileKudosListSkeleton from 'components/Profile/ProfileKudosListSkeleton';
import Profile from 'components/Profile';
import ProfileKudoItem from 'components/UI/ProfileKudoItem';
import {
  PaginatorInfoWrapper,
  PaginatoWrapper,
  TypographyEmptyState,
} from '../../components/Leaderboard/Leaderboard.styles';

type pageParams = {
  id: string;
};

const filterOptions = [
  { text: 'All time', value: 1, call: 'DESC' },
  { text: 'This week', value: 2, call: 'this-week' },
  { text: 'This month', value: 3, call: 'this-month' },
  { text: 'Last 6 months', value: 4, call: '6-month' },
  { text: 'Custom range', value: 5, call: 'custom' },
  { text: 'Oldest to newest', value: 6, call: 'ASC' },
];
const [allTimeFilter, , , , customRangeFilter] = filterOptions;

const ITEMS_PER_PAGE = 8;
const ProfilePage: React.FunctionComponent = () => {
  const { user } = useAuth0();
  const { id: registerID = '' } = useParams<pageParams>();
  const [range, setRange] = useState('');
  const [page, setPage] = useState(1);
  const [customRangeSelected, setCustomRangeSelected] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [firstSelectedDate, setFirstSelectedDate] = useState(new Date(0));
  const [secondSelectedDate, setSecondSelectedDate] = useState(new Date(0));
  const [filterName, setFilterName] = useState(allTimeFilter.text);
  const [prevFilterName, setPrevFilterName] = useState(allTimeFilter.text);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentTab, setCurrentTab] = useState('received');
  const [totalKudos, setTotalKudos] = useState(0);
  const [orderOrientation, setOrderOrientation] = useState('DESC');
  const {
    profileItems,
    total,
    totalReceivers,
    totalGivers,
    loading,
    user: profileUser,
  } = useProfileData(
    page,
    ITEMS_PER_PAGE,
    user?.email,
    range,
    firstSelectedDate,
    secondSelectedDate,
    customRangeSelected,
    registerID,
    searchTerm,
    currentTab,
    orderOrientation,
  );

  useEffect(() => {
    if (profileItems) setTotalKudos(total);
  }, [profileItems]);

  const showDate = (date: Date, text: string) => {
    const undefinedDate = isEqual(date, new Date(0));
    return undefinedDate ? text : format(date, 'LLL dd, yyyy');
  };

  const resetDates = () => {
    setFirstSelectedDate(new Date(0));
    setSecondSelectedDate(new Date(0));
  };

  const handleDateSelected = () => {
    const textDate = `${showDate(firstSelectedDate, 'start')} - ${showDate(
      secondSelectedDate,
      'end',
    )}`;
    setPage(1);
    setRange(customRangeFilter?.call);
    setFilterName(textDate);
    setIsOpen(false);
  };

  const handleClose = () => {
    setFilterName(prevFilterName);
    setIsOpen(false);
  };

  const searchInData = (search: string) => {
    setSearchTerm(search);
    setPage(1);
  };

  const filterKudos = (event: any) => {
    const { text: eventText, value: eventValue } = event;

    const getCall = (filterValue: number) => {
      return filterOptions.find((op) => op.value === filterValue)?.call || '';
    };

    switch (eventText) {
      case 'Custom range':
        setCustomRangeSelected(true);
        setIsOpen(true);
        break;
      case 'Oldest to newest':
        setCustomRangeSelected(false);
        setOrderOrientation(getCall(eventValue));
        break;
      case 'All time':
        setCustomRangeSelected(false);
        setOrderOrientation(getCall(eventValue));
        setRange('');
        break;
      default:
        setCustomRangeSelected(false);
        setRange(getCall(eventValue));
    }
  };

  const emptyStateTitle = 'No kudos yet!';
  const emptyStateBodyYours = `You haven't ${currentTab} kudos, but you can take the first step by recognizing your colleagues' efforts.`;
  const emptyStateBodyOthers = `This person hasn't ${currentTab} kudos, but you can take the first step by recognizing them.`;

  if (profileItems && user) {
    const renderProfileKudos = () => {
      const items = profileItems.map((kudo) => {
        return (
          <ProfileKudoItem key={`profile-kudo-${kudo.kudo_id}`} kudo={kudo} />
        );
      });
      return items.length ? (
        items
      ) : (
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          padding={5}>
          <TypographyEmptyState variant="headingSmall">
            {emptyStateTitle}
          </TypographyEmptyState>
          <TypographyEmptyState variant="bodyRegular">
            {registerID ? emptyStateBodyOthers : emptyStateBodyYours}
          </TypographyEmptyState>
        </Box>
      );
    };

    return (
      <>
        {profileUser.giverName !== '' ? (
          <Box>
            <Profile
              user={profileUser}
              totalGivers={totalGivers}
              totalRecievers={totalReceivers}
            />
            <div>
              <Modal
                headline="Please choose a date range"
                description=""
                onClose={handleClose}
                open={isOpen}>
                <DateRangePicker
                  cancelAction={handleClose}
                  applyAction={() => handleDateSelected()}
                  firstSelectedDate={firstSelectedDate}
                  secondSelectedDate={secondSelectedDate}
                  setFirstSelectedDate={setFirstSelectedDate}
                  setSecondSelectedDate={setSecondSelectedDate}
                  resetDates={resetDates}
                />
              </Modal>
            </div>
            <Box
              alignItems="center"
              display="flex"
              justifyContent="space-between"
              marginX="0px">
              <SearchInput
                submitHandler={searchInData}
                placeholder="Type any word to search"
                defaultValue={searchTerm}
              />
              <Box alignItems="right" minWidth="230px">
                <Dropdown
                  title={filterName}
                  options={filterOptions}
                  onChange={(event) => {
                    filterKudos(event);
                    setPage(1);
                    setPrevFilterName(filterName);
                    setFilterName(event.text);
                  }}
                />
              </Box>
            </Box>
          </Box>
        ) : (
          <ProfileSkeletonHeader />
        )}
        <Tabs
          defaultKey="received"
          onActiveChange={(tabKey) => {
            setCurrentTab(tabKey);
            setPage(1);
          }}>
          <TabItem tabKey="received" text="Kudos Received" />
          <TabItem tabKey="given" text="Kudos Given" />
        </Tabs>
        {!loading ? (
          <>
            <Stack align="center">{renderProfileKudos()}</Stack>
            {totalKudos > 0 && (
              <Box>
                <PaginatorInfoWrapper>
                  <PaginatorInfo
                    pageSize={ITEMS_PER_PAGE}
                    totalItems={totalKudos}
                    currentPage={page}
                  />
                </PaginatorInfoWrapper>
                {totalKudos > ITEMS_PER_PAGE && (
                  <PaginatoWrapper>
                    <Paginator
                      pageSize={ITEMS_PER_PAGE}
                      totalItems={totalKudos}
                      initialPage={1}
                      onSelectPage={setPage}
                      pageSync={page}
                    />
                  </PaginatoWrapper>
                )}
              </Box>
            )}
          </>
        ) : (
          <ProfileKudosListSkeleton />
        )}
      </>
    );
  }
  return <States state="error" />;
};
export default ProfilePage;
