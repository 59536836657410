import { FunctionComponent } from 'react';
import { PaginatorInfoWrapper, Bold } from './PaginatorInfo.styles';
import { PaginatorInfoProps } from './PaginatorInfo.types';

const PaginatorInfo: FunctionComponent<PaginatorInfoProps> = ({
  pageSize,
  totalItems,
  currentPage,
}) => {
  const firstItem = pageSize * (currentPage - 1) + 1;
  const lastItem =
    pageSize * currentPage < totalItems ? pageSize * currentPage : totalItems;

  return (
    <PaginatorInfoWrapper data-testid="paginator-info">
      Showing <Bold>{firstItem}</Bold> to <Bold>{lastItem}</Bold> of{' '}
      <Bold>{totalItems}</Bold> results
    </PaginatorInfoWrapper>
  );
};

export default PaginatorInfo;
