import { FunctionComponent } from 'react';
import { ButtonLink } from './PageLink.styles';
import { PageLinkProps } from './PageLink.types';

const PageLink: FunctionComponent<PageLinkProps> = (props) => {
  const { children, onClick, ...rest } = props;

  return (
    <ButtonLink type="button" onClick={onClick} {...rest}>
      <span>{children}</span>
    </ButtonLink>
  );
};

export default PageLink;
