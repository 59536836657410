import React, { useState, useRef, useEffect } from 'react';
import ARROW_ICON from 'styles/imgs/arrow-down.svg';
import { Option, Props as DropdownTypes } from './Dropdown.types';
import {
  DropdownContainer,
  MenuContainer,
  DropdownMenu,
  Icon,
  Title,
  DropdownHeader,
  DropdownItem,
} from './Dropdown.styles';

const Dropdown: React.FunctionComponent<DropdownTypes> = ({
  title,
  options,
  defaultValue,
  onChange,
  disabled,
}) => {
  const [isOpen, setOpen] = useState(false);
  const dropdownRef = useRef(null);

  const defaultOption = options.find((option) => option.value === defaultValue);
  const [selectedOption, setSelectedOption] = useState(defaultOption);

  const isClickOutside = (event: MouseEvent) => {
    // @ts-ignore
    return !dropdownRef.current.contains(event.target);
  };

  const clickOutsideHandler = (event: MouseEvent) => {
    if (isClickOutside(event)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', clickOutsideHandler);
    return () => {
      document.removeEventListener('mousedown', clickOutsideHandler);
    };
  }, []);

  const toggleMenu = () => {
    if (!disabled) {
      setOpen(!isOpen);
    }
  };

  const selectOption = (option: Option) => {
    setSelectedOption(option);
    // @ts-ignore
    onChange(option);
  };

  const isSelectedOption = (option: Option) => {
    if (selectedOption) {
      return option.value === selectedOption.value;
    }
    return false;
  };

  return (
    <DropdownContainer ref={dropdownRef} onClick={toggleMenu} isOpen={isOpen}>
      {/* @ts-ignore */}
      <DropdownHeader disabled={disabled}>
        <Title>{(selectedOption && selectedOption.text) || title}</Title>
        <Icon>
          <img src={ARROW_ICON} alt="Arrow Icon" />
        </Icon>
      </DropdownHeader>
      <MenuContainer>
        <DropdownMenu isOpen={isOpen}>
          {isOpen &&
            options.map((option) => {
              return (
                <DropdownItem
                  onClick={() => selectOption(option)}
                  isSelected={isSelectedOption(option)}
                  key={option.value}>
                  {option.text}
                </DropdownItem>
              );
            })}
        </DropdownMenu>
      </MenuContainer>
    </DropdownContainer>
  );
};

export default Dropdown;
