import styled from 'styled-components';

export type ModalProps = {
  show: boolean;
};

export const Container = styled.div<ModalProps>`
  display: ${(props) => (props.show ? 'block' : 'none')};
  z-index: 1300;
`;

export const ModalOverlay = styled.div`
  position: fixed;
  background: rgba(53, 60, 67, 0.8);
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 1300;
`;

export const ContentContainer = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 1400;
  overflow: hidden auto;
`;

export const Content = styled.div`
  outline: 0px;
  max-width: 28rem;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 1400;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 7px 14px 0px,
    rgba(0, 0, 0, 0.07) 0px 3px 6px 0px;
  top: 20%;
  margin: 3.75rem auto;
  border-radius: 4px;
`;

export const ModalHeader = styled.div`
  color: #354144;
  font-size: 22px;
  line-height: 35px;
  font-weight: 600;
  padding-top: 21px;
  padding-left: 24px;
  padding-right: 24px;
`;

export const ModalBody = styled.div`
  padding-right: 24px;
  padding-left: 24px;
`;

export const ModalFooter = styled.div`
  background: #fbfbfc;
  display: flex;
  justify-content: flex-end;
  padding: 1rem 1.5rem;
  border-radius: 4px;
`;
