import styled from 'styled-components';
import { Typography } from '@wizeline/patio-ui';
import { device } from 'constants/breakpoints';

export const SearchResult = styled(Typography)`
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  padding: 0;
  text-align: left;

  @media ${device.tablet} {
    font-size: 24px;
  }
`;
