import styled, { css } from 'styled-components';

export const Title = styled.div`
  color: #4c555f;
  font-size: 16px;
`;

export const Icon = styled.div`
  width: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
`;

// TODO: Replace css colors with theme colors
export const DropdownHeader = styled.div<{ disabled: boolean }>`
  border-radius: 4px;
  border: 1px solid #7d8895;
  height: 2.5rem;
  cursor: pointer;
  padding: 0.62rem 1rem 0.62rem 1rem;
  position: relative;

  ${(props) =>
    !props.disabled &&
    css`
      &:hover,
      &.selected {
        border-color: #008098;
      }
    `}

  ${Title} {
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    ${(props) => props.disabled && 'color: #7b8896'}
  }

  ${Icon} {
    position: absolute;
    top: 1rem;
    right: 1em;
  }

  ${(props) =>
    props.disabled &&
    css`
      background-color: #f2f4f5;
      color: #7b8896;
    `}
`;

export const DropdownContainer = styled.div<{
  isOpen: boolean;
}>`
  background-color: #ffffff;
  ${(props) =>
    props.isOpen &&
    css`
      border-radius: 4px;
      border: 1px solid #008098;

      ${DropdownHeader} {
        border: none;
        box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.01);
      }
    `}
`;

export const MenuContainer = styled.div`
  z-index: 3;
  position: relative;
`;
export const DropdownMenu = styled.div<{ isOpen: boolean }>`
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 10rem;
  box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);
  scroll-snap-type: y mandatory;
  background-color: #ffffff;
  position: absolute;
  width: 100%;
  border-radius: 4px;
  margin-top: 3px;

  ${(props) =>
    props.isOpen &&
    css`
      border: 1px solid #008098;
    `}
`;

export const DropdownItem = styled.div<{ isSelected: boolean }>`
  height: 2.3rem;
  padding-left: 0.8rem;
  vertical-align: middle;
  line-height: 2rem;
  color: #4c555f;
  cursor: pointer;
  ${(props) =>
    props.isSelected &&
    css`
      background-color: #f8f8fa;
      color: #00687c;
    `}

  &:hover {
    background-color: #f8f8fa;
    color: #00687c;
  }
`;
