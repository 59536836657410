import styled from 'styled-components';

export const DateSelectorContainer = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
`;

export const Bold = styled.span`
  font-weight: bold;
`;

export const DateContainer = styled.div`
  text-transform: capitalize;
  font-weight: bold;
  width: 160px;
  text-align: center;
  color: ${(props) => props.theme.colors.gray[100]};
`;
